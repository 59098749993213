
import { Spin } from '@arco-design/web-react'
import {  useEffect, useState } from 'react'
import { ManageApis } from '../../common/APIS/ManageApis'
import { FailedPopup } from '../../common/Utils/Utils'

const StaticScreen = ({ title }) => {
    const [content, setcontent] = useState('')
    const [loading, setloading] = useState(false)
   
    useEffect(
        () => {
            getData()
        }, [],)

    const getData = async () => {
        setloading(prev=> true)
        let param = title?.includes('Terms') ? 'TERMS_CONDITION' : "PRIVACY_POLICY"
        let path = global.staticcontent + param
        let res = await ManageApis('get', path, '', '')
        if (res.response_data.status) {
            setloading(false)
            setcontent(res.response_data.data.content)
        } else {
            setloading(false)
            FailedPopup(res)
        }
      }

    return (
        <Spin loading={loading}>
            {(content && !loading) &&
            <div  dangerouslySetInnerHTML={{__html: content}} >
          
            </div>
            }
       </Spin>
      
    )
}

export default StaticScreen