import Layout from "../../Components/Layout/Layout";
import { useCallback, useEffect, useState } from "react";
import { ManageApis } from "../../common/APIS/ManageApis";
import { useContext } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
import { Skeleton, Table } from "@arco-design/web-react";
import BackBtn from "../../Components/BackBtn/BackBtn";
import BranchList from "../../Components/BranchList/BranchList";
import {
  FailedPopup,
  customLogger,
  successPopup,
} from "../../common/Utils/Utils";
import PopupModal from "../../Components/PopupModal";
import InputField from "../../Components/InputField/InputField";
import { AlbumsOutline } from "react-ionicons";

const initialValue = {
  chit_no: "",
  branch_id: "",
  branch_name: "",
  pattern: "",
  message: "Want to know more about this",
};

function NewChits() {
  const [visible, setVisible] = useState(false);
  const [Message, setMessage] = useState("");
  const [Errors, setErrors] = useState({});
  const { token } = useContext(ContextAPI);
  const [list, setList] = useState([]);
  const [loader, setloader] = useState(false);
  const [filter, setfilter] = useState({ branch_id: "" });
  const [form, setform] = useState(initialValue);

  useEffect(() => {
    getNewChitsDetails();
    setMessage("");
  }, [filter.branch_id]);

  const handleChangeTable = useCallback(() => {
    getNewChitsDetails();
  }, []);

  const handleErrors = (name, message) => {
    customLogger(("NAME", name, message));
    setErrors({ name: name, message });
  };

  // get chit list
  const getNewChitsDetails = useCallback(async () => {
    if (filter.branch_id) {
      setloader(true);
      const reqstBody = {
        branch_ids: `${filter.branch_id}` || "",
      };

      let response = await ManageApis(
        "post",
        global.getNewChit,
        reqstBody,
        token
      );
      if (response.message == "Success") {
        customLogger("successfull///", response);
        setloader(false);
        if (response.response_data.status) {
          let data = [];
          data = response.response_data.data.docs;
          setList(data);
          customLogger("new arr--------", data);
        }
      } else {
        setloader(false);
        customLogger(response);
        setList([]);
        FailedPopup(response.message);
      }
    }
  }, [filter.branch_id]);

  const TableColumn = [
    {
      title: "Chit No",
      dataIndex: "",
      width: 160,
      render: (v, rec) => rec.temporaryChittyNo,
    },
    { title: "Sala", dataIndex: "", render: (v, rec) => rec.sala },
    {
      title: "No of Installments",
      dataIndex: "",
      render: (v, rec) => rec.noOfInstallments,
    },
    {
      title: "Chit Frequency",
      dataIndex: "",
      render: (v, rec) => rec.chittyFrequency,
    },
    {
      title: "Date Of Announcement",
      dataIndex: "",
      render: (v, rec) => rec.dateOfAnnouncement,
    },
    {
      title: "Proposed Date Of Commencement",
      dataIndex: "",
      render: (v, rec) => rec.proposedDateofCommencement,
    },
    {
      title: "Subscription",
      dataIndex: "",
      render: (v, rec) => rec.subscriptionAmount,
    },
    { title: "Class", dataIndex: "", render: (v, rec) => rec.chittyClass },
    { title: "Division", dataIndex: "", render: (v, rec) => rec.noOfDivisions },
    {
      title: "Status",
      dataIndex: "",
      render: (v, rec) => rec.status.replace("_", " "),
      headerCellStyle: {
        minWidth:'180px'
    },
    },
    {
      title: "Action",
      dataIndex: "action",
      fixed: "right",
      width: 150,
      render: (v, rec) =>
        loader ? (
          <Skeleton text={{ rows: 1, width: 100 }} />
        ) : (
          <div className="d-flex justify-content-start">
            <>
              {rec.requestSubmittedStatus ? (
                <div className="badge badge_success_soft font_12 fw_500 py-2">
                  Interest Sent
                </div>
              ) : (
                <button
                  className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 text-nowrap px-1"
                  onClick={() => handleOpenModal(rec)}
                >
                  Express Interest
                </button>
              )}
            </>
          </div>
        ),
    },
  ];

  const handleChangeBranch = (value) => {
    setfilter((prev) => ({ ...prev, branch_id: value }));
  };
  const handleExpressIntrest = async () => {
    //if (form.message) {
    setloader(true);
    const reqstBody = form;
    customLogger("reqstBody--------", reqstBody);
    //debugger
    let response = await ManageApis(
      "post",
      global.expressUserInterest,
      reqstBody,
      token
    )
      .then((res) => {
        customLogger("express interest api resp", res);
        const { status, data, message } = res.response_data;

        if (status) {
          getNewChitsDetails();
          setform(initialValue);
          setVisible(false);
          setloader(false);
          setMessage("");
          if (data?.newRequest) {
            successPopup(message);
          } else {
            FailedPopup(message);
          }
        } else {
          FailedPopup(message);
          setloader(false);
          setVisible(true);
        }
      })
      .catch((err) => {
        setloader(false);
        FailedPopup(response);
      });

    // } else {
    //   if (!form.message) {
    //     FailedPopup('Please enter message')
    //     return
    //   }
    // }
  };
  const handleOpenModal = (item) => {
    setVisible(true);
    //setform(prev => ({ ...prev, branch_id: value.branchId, chit_no: value.temporaryChittyNo }))
    setform((prev) => ({
      ...prev,
      branch_id: item.branchId,
      chit_no: item.temporaryChittyNo,
      branch_name: item.branchName,
      pattern: `${item.subscriptionAmount}X${item.noOfInstallments}`,
    }));
  };
  const handleClose = () => {
    setVisible(false);
    setform(initialValue);
  };

  return (
    <Layout loading={loader}>
      <div className="pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1">
        <div>
          <div className="mb-3">
            <div className="align-items-center d-flex justify-content-between flex-wrap">
              <div className="col-12 col-sm-5 d-flex pt-2">
                <AlbumsOutline
                  height="23px"
                  width="23px"
                  color={""}
                  className="text_master"
                />
                <h3 className="fw_600 font_18 text_master ms-2 mb-0">
                  New Chits
                </h3>
              </div>
              {/* <BackBtn title={'New Chits'} /> */}
            </div>
          </div>
          <div className="p-3 bg-white br_10 shadow_sm">
            <div>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-5 mb-3">
                  <div className="d-flex align-items-end w-100">
                    <div className="flex-fill">
                      {/* <label className="mb-0 pb_2x text_master fw_500 font_13">
                        Branch<span className="text-danger">*</span>
                      </label>
                      <select className="form-control rounded font_12">
                        <option>Option1</option>
                      </select> */}
                      <BranchList
                        onChange={handleChangeBranch}
                        value={filter.branch_id}
                      />
                    </div>
                    <div className="d-none">
                      <button className="btn btn-master-line flex-fill flex-md-grow-0 font_12 fw_500 px-3 px_btn rounded">
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3">
              {filter.branch_id ? (list.length > 0 ? (
                  <div className="arco_table1 table-responsive custom_scroll1">
                    {/* <DataTable 
                    columnData={TableColumn} 
                    rowData={list} 
                    onChangeTable={handleChangeTable} 
                    total={totolPages} 
                    current={currentPage} 
                  />
                 */}                  
                 <Table
                    columns={TableColumn}
                    data={list}
                    pagination={false}
                    onChange={handleChangeTable}
                    borderCell
                    // rowSelection={{}}
                     scroll={{
                      x: 1600,
                      y: 400,
                    }}
                  />
                </div>
              ) : (
                 // <NoDataScreen message={'Select a branch to list the chit details'} />
                <div className="p-3 bg-white br_10 shadow_sm">
                  <div className="d-flex">
                    <div className="m-auto">
                      <div className="text-center d-grid py-5 text_gray7">
                        <i className="far fa-file-alt font_18"></i>
                        <p className="mb-0 mt-2">No Chits Found</p>
                        {/* <p className="mb-0 mt-1 font_12">please choose the branch to view chits</p> */}
                      </div>
                    </div>
                  </div> 
                </div>
                )
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {visible && (
        <PopupModal
          title="Express Interest"
          size="md"
          handleClose={handleClose}
          visible={visible}
        >
          <div>
            <div className="mb-3">
              {/* <label className="mb-0 pb_2x text_master fw_500 font_14">
                Message<span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control rounded font_12" rows={6}
                placeholder="Enter your message here.."></textarea> */}
              <InputField
                //required
                //errorMessage={Errors?.message}
                //seterrorMessage={handleErrors}
                label={"Enter your message"}
                placeholder={"Enter Here"}
                type={"textarea"}
                onchange={(text) =>
                  setform((prev) => ({ ...prev, message: text.slice(0, 250) }))
                }
                // onchange={(text) => setform(text)}
                value={form.message}
                rows={6}
              />
            </div>
            <div>
              <button
                className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100"
                onClick={handleExpressIntrest}
              >
                Submit
              </button>
            </div>
          </div>
        </PopupModal>
      )}
    </Layout>
  );
}
export default NewChits;
