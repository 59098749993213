import React, {   useRef, useState } from 'react';
import { FailedPopup, findMinutesDiff, getSessionStorage, setSessionStorage } from '../../common/Utils/Utils';
import InputField from '../InputField/InputField';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { Spin } from '@arco-design/web-react';
import { RefreshOutline } from 'react-ionicons'



const Captcha = ({ onSubmit }) => {
    const inputRef = useRef(null);
    const [ReAttemptCount, setReAttemptCount] = useState(0)
    // const [captchaChallenge, setCaptchaChallenge] = useState(generateCaptchaChallenge());
    // const [userInput, setUserInput] = useState('');
    const [verificationResult, setVerificationResult] = useState('');
    const [expression, setExpression] = useState('');
    const [userAnswer, setUserAnswer] = useState('');
    const [disableRefreshButton, setdisableRefreshButton] = useState(false)
    const [time, settime] = useState(0)
    const [IsLoading, setIsLoading] = useState(false)

    const fetchStoredData = useCallback(async () => {
        const storedData = await getSessionStorage('captchaAttempt');
        if (storedData) {
          let findTimeDifference = findMinutesDiff(new Date(), new Date(storedData?.time))
          if (findTimeDifference < 10) {
              setdisableRefreshButton(true)
              settime(10 - findTimeDifference)
          } else {
              generateExpression()
          }
        }
      }, []);


    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        fetchStoredData();
    
        // for initial calling
        if (time === 0) {
          generateExpression();
        }
    
        return () => {
          // Cleanup code here if needed
        };
      }, []);




    // Function to generate a random math expression
    const generateExpression = () => {
        if (ReAttemptCount <= 5) {
            let num1, num2, operator, result;
            do {
                num1 = Math.floor(Math.random() * 10) + 1;
                num2 = Math.floor(Math.random() * 10) + 1;
                operator = ['+', '-'][Math.floor(Math.random() * 2)];
                result = calculateExpression(num1, operator, num2);
            } while (result < 0); // Ensure the result is non-negative

            setExpression(`${num1} ${operator} ${num2} = ?`);
            drawCaptcha(num1,operator,num2)
            setUserAnswer('')
            inputRef?.current?.focus();
            setReAttemptCount(prev => prev + 1)
            return result;
        } else {
            setdisableRefreshButton(prev => true)
            let obj = { status: true, time: new Date() }
            settime(10)
            //storeAsyncData('captchaAttempt', JSON.stringify(obj))
            setSessionStorage('captchaAttempt', JSON.stringify(obj));
            FailedPopup(`You've exceeded your maximum attempts. Please try again after some time.`)
        }

    };

    // Function to safely calculate a math expression
    const calculateExpression = (num1, operator, num2) => {
        num1 = Number(num1)
        num2 = Number(num2)
        switch (operator) {
            case '+':
                return num1 + num2;
            case '-':
                return num1 - num2;
            case '*':
                return num1 * num2;
            default:
                return NaN; // Invalid operator
        }
    };

    const handleCheckAnswer = () => {
        
        if (userAnswer) {
            setIsLoading(true)
            //debugger
            const correctAnswer = calculateExpression(...expression.split(' '));

            if (parseInt(userAnswer) === correctAnswer) {
                setVerificationResult(`CAPTCHA verified!`)
                setIsLoading(false)
                onSubmit()
            } else {
                // Handle incorrect answer
                setVerificationResult('Incorrect answer. Please try again.');
                setUserAnswer('');
                generateExpression();
                setIsLoading(false)
            }

        }
    };
    const drawCaptcha = (num1, operator, num2) => {
        const canvas = document.getElementById('captchaCanvas');
        const ctx = canvas.getContext('2d');
    
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    
        ctx.font = '20px Arial';

        // Calculate the text width
        const textWidth = ctx.measureText(`${num1} ${operator} ${num2} =`).width;
        
        // Calculate the center position
        const x = (canvas.width - textWidth) / 2;
        //const y = canvas.height / 2;
        
        // Draw the text at the center
        ctx.fillText(`${num1} ${operator} ${num2} =`, x, 35);
      };
    
      const handleChange = (value) =>
      {
        setUserAnswer(value)
        setVerificationResult('');

      }
  


    return (
        <>
            <Spin loading={IsLoading}>
                    <div className='bg_gray text-center mb-3 rounded'>
                        {/* {expression} */}
                        <canvas id="captchaCanvas" width="200" height="50"></canvas>
                    </div>
                    <div>
                        <InputField 
                            disabled={disableRefreshButton}
                            value={userAnswer}
                            placeholder={"Calculate the answer and enter here"} 
                            className={"px-3 h_45x w-100"} 
                            type={'text'} 
                            onchange={handleChange} 
                            inputRef={inputRef}
                        /> 
                    </div>
                    <div className="d-flex gap-2">
                        <button
                        type="submit"
                        className="btn px_btn btn-master px-2 rounded w-50 fw_500"
                        onClick={generateExpression}
                        disabled={disableRefreshButton}
                        >
                            <RefreshOutline
                                color={'#fff'} 
                                height="20px"
                                width="20px"
                            />
                        </button>
                        
                        <button
                        type="button"
                        className="btn px_btn btn-master px-2 rounded w-100 fw_500"
                        disabled={disableRefreshButton || !userAnswer}
                        onClick={handleCheckAnswer}
                        >
                        {'Submit'}
                        </button>
                    </div>
                    <div>
                        {verificationResult.includes('CAPTCHA verified!')?
                            <span 
                                className='d-block font_10 pt-1 text-center text-success w-100'>{disableRefreshButton ? `Limit exceeded, please try after ${time} ${time > 1 ? 'minutes' : 'minute'}` : verificationResult}</span>
                        :
                            <span 
                                className='d-block font_10 pt-1 text-center text-danger w-100'>{disableRefreshButton ? `Limit exceeded, please try after ${time} ${time > 1 ? 'minutes' : 'minute'}` : verificationResult}</span>
                        }
                        
                    </div>
            </Spin>

        </>
       

    );
};



export default Captcha;

