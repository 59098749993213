import { customLogger } from "../Utils/Utils";
import { DELETE_API, GET_API, POST_API } from "./APIS";

export const ManageApis = async (method, path, params, headers) => {

  if (method === "post") {
    //customLogger("apicall params", method, baseUrl + path, params)
    let results = await POST_API(params, path, headers);
    // return results
    // results.then((results)=>{
    if (results.status_code) {
      customLogger("manageapiresponse", results)
      return results;

    }
    else {
      return results.message;
    }
    // });
  }
  else if (method === "get") {

    //customLogger("apicall params", method, path, params, headers)
    let results = await GET_API(path, headers, params);
    // return results.then((results)=>{
    if (results.status_code) {

      //customLogger("manageapiresponse", results)
      return results;

    }
    else {

      return results.message;
    }
    // });
  }
  else if (method === "delete") {

    //customLogger("apicall params", method, path, params, headers)
    let results = await DELETE_API(path, headers, params);
    // return results.then((results)=>{
    if (results.status_code) {

      //customLogger("manageapiresponse", results)
      return results;

    }
    else {

      return results.message;
    }
    // });
  }
};
