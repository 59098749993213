import { CalculatorOutline } from "react-ionicons"
import { useContext, useEffect, useState } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
import { ManageApis } from "../../common/APIS/ManageApis";
import BackBtn from "../../Components/BackBtn/BackBtn";
import Layout from "../../Components/Layout/Layout";
import moment from "moment"
import { FailedPopup, customLogger, generateNonce } from "../../common/Utils/Utils";
import { useLocation } from "react-router-dom";
import InputField from "../../Components/InputField/InputField";


function RequestChat() {
  const data = useLocation();
  customLogger('data', data.state._id)
  const { token } = useContext(ContextAPI)

  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState('')
  const [previousReqstBody, setpreviousReqstBody] = useState('')
  const [nonce, setnonce] = useState('')


   const { _id, request_to_txt,request_to, branch_name, subject, request_type, request_date, request_type_txt } = data.state;

  const [messages, setMessages] = useState([]);


  useEffect(() => {
     loadChat()
  }, []);

  const loadChat = async () => {
    setIsLoading(true);
    const reqstBody = {
      request_id: _id
    };
    let response = await ManageApis("post", global.listchat, reqstBody, token)
    if (response.response_data.status) {
      setIsLoading(false);
      customLogger('chat list', response.response_data.data.docs);
      const resp = response.response_data.data.docs.sort((a, b) => a.created_at - b.created_at)
  
      customLogger({ resp })
      const newMessages = resp.map((value, index) => (

        {
          _id: index + 1,
          text: value.comment_text,
          // createdAt: moment(value.created_at, "DD-MM-YYYY hh:mm A").format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'),
          createdAt: value.created_at,
          user: {
            _id: value.comment_type,
          },
        }));
      customLogger('chat', newMessages);
      setMessages(newMessages);
    } else {
      setIsLoading(false);
      customLogger('failed resp', response.response_data.message);
      FailedPopup(response);
      
    }
  }
  const renderItem = () => {

    return (
      messages?.map(itm =>
   
        itm.user._id == 1 ? 
          <li className="d-flex justify-content-end ms-3">
            <div className="bg_master border_radius_chat mb-3 p-2 width_fit_content">
              <h3 className="text-white font_12 font_500 word_break" >{itm.text}</h3>
              <p className="text-white font_8 font_400 mb-0 text-end">{itm.createdAt}</p>
            </div>
          </li>
           
        :
          <li className="d-flex me-3">
            <div className=" bg_master2 border_radius_chat_2 mb-3 p-2 width_fit_content">
              <h3 className="text-white font_12 font_500 word_break" >{itm.text}</h3>
              <p className="text-white font_8 font_400 mb-0 text-start">{itm.createdAt}</p>
            </div>
          </li>  
      )
    );
  };


  const onSend = async () => {
    if(msg.trimStart()){
      const messageData = msg;
      customLogger("messageData : ", messageData);
      let tempNonce = ''
      let reqstBody = {
        comment_text: messageData,
        requestId: _id
      }
      if (JSON.stringify(reqstBody) !== previousReqstBody) {
        tempNonce = generateNonce(32)
        setnonce(tempNonce)
      }
      reqstBody = { ...reqstBody, nonce: tempNonce }
      let response = await ManageApis("post", global.savechat, reqstBody, token)
      //debugger
      if (response.response_data.status) {
        setpreviousReqstBody(JSON.stringify(reqstBody))
        customLogger('response', response.response_data.message);

        const today = new Date()
        setMessages([
          ...messages,
          { 
              text: messageData,
              createdAt: moment(today, "DD-MM-YYYY hh:mm A").format("DD-MM-YYYY HH:mm A"),
              user: {
                _id: 1,
              },
          }
        ]);
        
      } else {
        FailedPopup(response);
      }
      
      setMsg("")
    }else{
      FailedPopup("Please enter a message..");
    }
  };
  return (
      <>
        <Layout loading={isLoading}>
        <div className='pt-3 pb-4 px-4 '>	
          <div>
            <div className="mb-3">
              <div className='align-items-center d-flex justify-content-between flex-wrap'>
                <BackBtn title={'Chat'} />
              </div>
            </div>
            <div className="bg-white br_10 shadow_sm p-1">
              <div className="d-flex flex-column justify-content-between overflow-auto scrollbar1 chatbox_head">
                <div className="position-sticky border-bottom top-0 zi_1 pt-3 px-3 bg-white">
                  <div className="row">
                    <div className="col-6 pb-0 pb-sm-2">
                        <div className="row">
                            <div className="col-12 col-md-4 font_13 font_600">Requested to</div>
                            <div className="col-12 col-md-8 pt-1 pt-sm-0 mb-3 mb-sm-0">
                                <div className="d-flex align-items-center">
                                    <span className="pe-1 d-none d-md-block">:</span>
                                    <span className="text_gray8 font_13 font_400 word_break">{request_to_txt}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 pb-0 pb-sm-2">
                        <div className="row">
                            <div className="col-12 col-md-4 font_13 font_600">Subject</div>
                            <div className="col-12 col-md-8 pt-1 pt-sm-0 mb-3 mb-sm-0">
                                <div className="d-flex align-items-center">
                                    <span className="pe-1 d-none d-md-block">:</span>
                                    <span className="text_gray8 font_13 font_400 word_break">{subject}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(request_to == 1 && branch_name) ?
                    <>
                     <div className="col-6 pb-0 pb-sm-2">
                        <div className="row">
                            <div className="col-12 col-md-4 font_13 font_600">Branch Name</div>
                            <div className="col-12 col-md-8 pt-1 pt-sm-0 mb-3 mb-sm-0">
                                <div className="d-flex align-items-center">
                                    <span className="pe-1 d-none d-md-block">:</span>
                                    <span className="text_gray8 font_13 font_400 word_break">{branch_name}</span>
                                </div>
                            </div>
                        </div>
                      </div>
                    </>
                    :""}
                   
                    {(request_type == 2) ?
                      <>
                        <div className="col-6 pb-0 pb-sm-2">
                            <div className="row">
                                <div className="col-12 col-md-4 font_13 font_600">Scheme</div>
                                <div className="col-12 col-md-8 pt-1 pt-sm-0 mb-3 mb-sm-0">
                                    <div className="d-flex align-items-center">
                                        <span className="pe-1 d-none d-md-block">:</span>
                                        <span className="text_gray8 font_13 font_400 word_break">{data.state.scheme_type_txt}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 pb-0 pb-sm-2">
                            <div className="row">
                                <div className="col-12 col-md-4 font_13 font_600">Sub Scheme</div>
                                <div className="col-12 col-md-8 pt-1 pt-sm-0 mb-3 mb-sm-0">
                                    <div className="d-flex align-items-center">
                                        <span className="pe-1 d-none d-md-block">:</span>
                                        <span className="text_gray8 font_13 font_400 word_break">{data.state.subscribed_scheme}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </>
                      :
                      ""
                    }
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-end px-2 pt-2">
                      <ul className="list-unstyled mb-0 ">
                      {
                        renderItem()
                      }
                        {/* <li className="text-center py-2">
                            <h2 className="text-black-50 mb-0 font_12 font_600">Aug 21, 2023</h2>
                        </li>
                        <li className="d-flex justify-content-end ms-3">
                        <div className="bg_master border_radius_chat mb-3 p-2 width_fit_content">
                          <h3 className="text-white font_12 font_500">hi,Rahul....</h3>
                          <p className="text-white font_8 font_400 mb-0 text-end">8.08 PM</p>
                        </div>
                        </li>
                        <li className="d-flex justify-content-end ms-3">
                        <div className="bg_master border_radius_chat mb-3 p-2 width_fit_content">
                          <h3 className="text-white font_12 font_500">hi,Rahul....</h3>
                          <p className="text-white font_8 font_400 mb-0 text-end">8.08 PM</p>
                        </div>
                        </li>
                        <li className="d-flex justify-content-end ms-3">
                        <div className="bg_master border_radius_chat mb-3 p-2 width_fit_content">
                          <h3 className="text-white font_12 font_500">hi,Rahul....</h3>
                          <p className="text-white font_8 font_400 mb-0 text-end">8.08 PM</p>
                        </div>
                        </li>
                        <li className="d-flex justify-content-end ms-3">
                        <div className="bg_master border_radius_chat mb-3 p-2 width_fit_content">
                          <h3 className="text-white font_12 font_500">hi,Rahul....</h3>
                          <p className="text-white font_8 font_400 mb-0 text-end">8.08 PM</p>
                        </div>
                        </li>
                        <li className="d-flex justify-content-end ms-3">
                        <div className="bg_master border_radius_chat mb-3 p-2 width_fit_content">
                          <h3 className="text-white font_12 font_500">hi,Rahul....</h3>
                          <p className="text-white font_8 font_400 mb-0 text-end">8.08 PM</p>
                        </div>
                        </li>
                        <li className="d-flex justify-content-end ms-3">
                        <div className="bg_master border_radius_chat mb-3 p-2 width_fit_content">
                          <h3 className="text-white font_12 font_500">hi,Rahul....</h3>
                          <p className="text-white font_8 font_400 mb-0 text-end">8.08 PM</p>
                        </div>
                        </li>
                        <li className="d-flex justify-content-end ms-3">
                        <div className="bg_master border_radius_chat mb-3 p-2 width_fit_content">
                          <h3 className="text-white font_12 font_500">hi,Rahul....</h3>
                          <p className="text-white font_8 font_400 mb-0 text-end">8.08 PM</p>
                        </div>
                        </li>
                        <li className="d-flex me-3">
                        <div className=" bg_master2 border_radius_chat_2 mb-3 p-2 width_fit_content">
                          <h3 className="text-white font_12 font_500">hi,Rahul....</h3>
                          <p className="text-white font_8 font_400 mb-0 text-start">8.08 PM</p>
                        </div>
                        </li> */}
                      </ul>

                      {(request_type_txt !== 'open') ?
                      <>
                        <div className="position-sticky bottom-0 pb-3 bg-white pt-3 pe-1 border-top bottom-0 d-flex align-items-center w-100">
                          <InputField marginBottom placeholder={"Type here.."} className={"px-3 br_25 h_45x w-100"} type={'text'} value={msg} onchange={(value) => setMsg(value)} /> 
                          <i className="fas fa-location-arrow fa-2x cursor-pointer ps-1 text_masterdark send_message_icon" onClick={onSend}></i>
                        </div>
                        
                      </>
                      :
                        <div className="position-sticky bottom-0 pb-3 bg-white pt-3 pe-1 border-top bottom-0 d-flex align-items-center w-100 btn-disable">
                          <InputField marginBottom placeholder={" "} className={"px-3 br_25 h_45x w-100"} type={'text'} /> 
                          <i className="fas fa-location-arrow fa-2x cursor-pointer ps-1 text_masterdark send_message_icon"></i>
                        </div>
                    }

                    
                      
                    
                </div>
              </div>
              </div>
          </div>
        </div>

        </Layout>


      </>
  )
}
export default RequestChat