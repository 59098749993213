import React from 'react'

const NodataScreen = ({ title = 'No data found', desc = '' }) => {
    return (
        <div className="p-3 bg-white br_10 shadow_sm">
            <div className="d-flex">
                <div className="m-auto">
                    <div className="text-center d-grid py-5 text_gray7">
                        <i class="far fa-file-alt font_18"></i>
                        <p className="mb-0 mt-2">{title}</p>
                        <p className="mb-0 mt-1 font_12">{desc}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NodataScreen