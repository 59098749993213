import React, { useContext, useEffect, useState } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
import { Spin } from "@arco-design/web-react";
// import { useNavigation } from "react-router-dom";
import { ManageApis } from "../../common/APIS/ManageApis";
import BranchListDropdown from '../../Components/BranchList/BranchList'
import { FailedPopup, customLogger, generateNonce, successPopup } from "../../common/Utils/Utils";
import InputField from "../../Components/InputField/InputField";

function AddGeneralRequestTab(props) {
  const {index,setVisible,refreshGenTab} = props
  const { token } = useContext(ContextAPI)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [errors, setErrors] = useState({});
  const [NewRequestData, setNewRequestData] = useState({
    request_to: '',
    branch: '',
    subject: 'General Request',
    requestType: 1,
    request: ''
  });


  const [BranchList, setBranchList] = useState([])
  const [RequestTo, setRequestTo] = useState([])
  // const navigation = useNavigation()
  const [previousReqstBody, setpreviousReqstBody] = useState('')
  const [nonce, setnonce] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    customLogger("index:", index);
    if (index == 0) {
      ListBranch()
      ListRequestTo()
    }
  }, [])

  useEffect(() => {
    if (NewRequestData.request_to) {
      setNewRequestData(prev => ({ ...prev, branch: '' }))
    }
  }, [NewRequestData.request_to])



  // list Branch details
  const ListBranch = async () => {
    setIsModalLoading(true)
    const path = global.listBranch
    let response = await ManageApis('get', path, "", token)
    if (response.message == 'Success') {
      //customLogger("Branch list", response.response_data.data.docs)
      let temArray = response.response_data.data.docs
      let temArray1 = []
      Object.keys(temArray).forEach(key => (temArray1.push({
        'key': temArray[key].branch_name, 'value': temArray[key].branch_id
      })))
      setBranchList(temArray1)
      setIsModalLoading(false)
      //customLogger('new',temArray1);
    } else {
      setIsModalLoading(false)
      FailedPopup(response)
    }
  }

  // list RequestTo dropdown
  const ListRequestTo = async () => {
    setIsModalLoading(true)
    const path = global.getRequestOwner
    let response = await ManageApis('get', path, "", token)
    if (response.message == 'Success') {
      // customLogger("RequestTo", response.response_data.data.result)

      let temArray = response.response_data.data.result
      let temArray1 = []
      Object.keys(temArray).forEach(key => (temArray1.push({
        'key': temArray[key].request_to, 'value': temArray[key].type
      })))
      customLogger("ar",temArray1);
      setRequestTo(temArray1)
      // customLogger('new request to', temArray1);
      setIsModalLoading(false)
    } else {
      FailedPopup(response)
      setIsModalLoading(false)
    }
  }


  
  // handle change  set all fields value to object state
  const handleOnchange = (text, input) => {

    if (input == "request") {
      setNewRequestData((prevState) => ({ ...prevState, [input]: text.slice(0, 250) }));
    } else {
      setNewRequestData((prevState) => ({ ...prevState, [input]: text }));
    }
    // customLogger("first", userData);
  };

  // validate field and set error to state
  const handleError = (error, input) => {
    setErrors((prevState) => ({ ...prevState, [input]: error }));
  };

  const handleButtonClick = () => {
    // Disable the button
    setIsButtonDisabled(true);
    // Enable the button after 5 seconds
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };
  
  // handle  verify
  const handleSubmitPress = async () => {
    handleButtonClick();
    // call validate fn and is true call backend api
    validate();
  };
  const validate = async () => {
    let isValid = true;
    if (!NewRequestData.request_to) {
      handleError("Please select Request to", "request_to");
      isValid = false;
    }
    if (NewRequestData.request_to == 1) {
      if (!NewRequestData.branch) {
        handleError("Please select a Branch", "branch");
        isValid = false;
      }
    }
    if (!NewRequestData.request) {
      handleError("Please enter Request", "request");
      isValid = false;
    }

    if (isValid) {

      customLogger("#####   READY TO CALL API  #####");
      submitRequest()
    } else {
      FailedPopup("Fill all mandatory fields");
    }
  };

  
  const submitRequest = async () => {
    setIsModalLoading(true);
    const reqstBody = {
      request_to: NewRequestData.request_to,
      subject: NewRequestData.subject,
      request_type: NewRequestData.requestType,
      comment_text: NewRequestData.request
    }
    let newreqstBody = (NewRequestData.request_to == 1) ? { ...reqstBody, branch_id: NewRequestData.branch } : reqstBody
    let tempNonce = ''
    if (newreqstBody) {
      //debugger
      if (JSON.stringify(newreqstBody) !== previousReqstBody) {
        tempNonce = generateNonce(32)
        setnonce(tempNonce)
      }
    }
    newreqstBody = { ...newreqstBody, nonce: tempNonce }
    let response = await ManageApis("post", global.createRequest, newreqstBody, token)
    if (response.message == 'Success') {
      setpreviousReqstBody(JSON.stringify(newreqstBody))
      setIsModalLoading(false);
      successPopup(response.response_data.message);
      refreshGenTab()
      setVisible(false)
      //navigate("My Request")
    } else {
      setIsModalLoading(false);
      customLogger("err", response)
      FailedPopup(response);
    }

  }




  return (
  <>
    <Spin dot loading={isModalLoading}>
      <div>
        <div className="mb-3">
          <InputField 
            required
            label={'Request To'} 
            type={'select'}  
            onchange={(text) => handleOnchange(text, "request_to")}
            onfocus={() => handleError(null, "request_to")}
            errorMessage={errors?.request_to}
            seterrorMessage={handleError}
            options={RequestTo} 
            value={NewRequestData.request_to}
          />
        </div>
        {NewRequestData.request_to == 1 ?
          <div className="mb-3">
            <BranchListDropdown
                isUserBranch
                selectedItem={(id) => handleOnchange(id, "branch")}
                label={'BRANCH'}
                isRequied 
                errorMessage={errors?.branch}
                seterrorMessage={handleError}
                onChange={(text) => handleOnchange(text, "branch")}
                onFocus={() => handleError(null, "branch")}
                onClear={() => setNewRequestData(prev => ({ ...prev, branch: '' }))}
              />
            </div>
            : ""
          }
        <div className="mb-3">
          <InputField 
            required
            errorMessage={errors?.request}
            seterrorMessage={handleError}
            label={"Request"}
            placeholder={'Enter Here'} 
            type={'textarea'}  
            onchange={(text) => handleOnchange(text, "request")}
            onfocus={() => handleError(null, "request")}
            rows={5} 
            value={NewRequestData.request}
            helperText={"Max allowed characters 250"}
          />
        </div>
        <div>
          <button className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100"
            disabled={isButtonDisabled}
            onClick={isButtonDisabled? ()=>{return} : handleSubmitPress}
           >{isButtonDisabled ? 'Submitting...' : 'Submit'}</button>
        </div>
      </div>
    </Spin>
  </>
  )
  
}

export default  AddGeneralRequestTab