
import React, { useContext, useState } from "react"
import Logo from "../assets/images/Logo.png"
import { BagHandleOutline, MenuOutline, NotificationsOutline } from 'react-ionicons'
import { Dropdown, Nav, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Notification from "../Screens/NotificationScreen/Notification";
import { ContextAPI } from "../common/Context/ContextApi";
import { useLocation } from "react-router-dom";






function Header({ handleToggleBtn }) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { notificationsDetails,setnotificationsDetails, HomeData, setkycModal } = useContext(ContextAPI)

  
  const [toggled, setToggled] = useState(false);
  // const [notificationsDetails] = useState(0)
  const toggleChange = () => {
    setToggled(!toggled);
    handleToggleBtn(toggled)
  }

  const activeAccounts = HomeData.no_of_customer_accounts - HomeData.no_of_notLinkedAccounts
  const handleNavigate=()=>{
    if (!HomeData.is_min_kyc_verified) {
      setkycModal(true)
    } else if (HomeData.no_of_notLinkedAccounts !== 0) {
      navigate("/myaccount")
    }
  }
  const handleNotifClick = () => {
    setnotificationsDetails(prev => ({ ...prev, notification_count: 0 }))
  }

  return (
    <>
  {Object.values(HomeData).some(((itm)=>(!itm)))? 
      (!HomeData.is_min_kyc_verified || activeAccounts === 0 || HomeData.no_of_customer_accounts == 0) &&
        <>
          <div className="badge badge_danger_soft font_12 fw_500 px-3 py-1 w-100  ws_normal">
            {!HomeData.is_min_kyc_verified ?
              `It is necessary to verify your PAN for linking your accounts`
              :
              HomeData.no_of_customer_accounts == 0 ?
                'PAN Updated successfully, however no customer accounts matched with this PAN, Please contact the Branch to update your PAN in the system'
                :
                activeAccounts === 0 ?
                  `It is necessary to link your accounts for the proper usage of app, please link the account`
                  :
                  ''
            }

            {(!HomeData.is_min_kyc_verified || activeAccounts === 0) &&
                <a className="cursor-pointer ms-1 px_hover_1 text_master" onClick={handleNavigate}>{!HomeData.is_min_kyc_verified ? 'Verify your PAN' : HomeData.no_of_notLinkedAccounts !== 0 && 'Link Accounts'}</a>
            }
          </div>
        </>
        
        :""
      }
      <div className="px-3 py-3 d-flex justify-content-between align-items-center bg-white">
        <div className="d-flex justify-content-between align-items-center">
          <div className="sidebar_toggle round_sm d-flex bg_gray2 rounded-circle me-3 cursor-pointer zi_10" onClick={toggleChange}>
            <MenuOutline
              color={'#00000'}
              className="m-auto text_master"
            />
          </div>
          <img src={Logo} className="logo" />

        </div>
        <div className="d-flex">
          <div className="cursor-pointer" onClick={handleNotifClick}>
            <Dropdown>
              <Dropdown.Toggle variant="unset" className="me-3">
                <div className="round_sm d-flex bg_gray2 rounded-circle cursor-pointer position-relative">
                  <NotificationsOutline
                    color={'#00000'}
                    className="m-auto text_master"
                  />
                  {!!notificationsDetails.notification_count &&

                    <span className="bg_masterdark font_10 p_1_3x position-absolute rounded-pill start-0 text-center text-white top-0 min_w_16x ms_26x mt_n3x">{notificationsDetails.notification_count}</span>
                  }
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="NotifMenu">
                <Notification />
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {/* this menu only visible the path not cart */}
          {!pathname.includes('/cart') &&
            <Link to={'/cart'} className="round_sm d-flex bg_gray2 rounded-circle cursor-pointer position-relative">
              <BagHandleOutline
                color={'#00000'}
                className="m-auto text_master"
              />
              {!!notificationsDetails.cart_count &&
                <span
                  className="bg_masterdark font_10 p_1_3x position-absolute rounded-pill start-0 text-center text-white top-0 min_w_16x ms_26x mt_n3x">
                  {notificationsDetails.cart_count}
                </span>
              }
            </Link>
          }
        </div>
      </div>
    


    </>
  )
}

export default Header