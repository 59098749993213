import React from "react";
import { ChevronBackOutline } from "react-ionicons";
import { useNavigate } from "react-router-dom";

const BackBtn = ({ handleBackBtn, title }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    if (window.history.state?.idx === 0 || window.history.state === null) {
      navigate("/");
    } else navigate(-1);
  };
  return (
    <div className="col-12 col-sm d-flex align-items-center">
      <div
        onClick={handleBackBtn || handleBack}
        className="round_sm d-flex bg_gray6 rounded-circle cursor-pointer d-inline-flex px_hover_1"
      >
        <ChevronBackOutline
          className="m-auto"
          color={"#00000"}
          height="20px"
          width="20px"
        />
      </div>
      {title && (
        <h3 className="fw_600 font_18 text_master ms-2 mb-0">{title}</h3>
      )}
    </div>
  );
};

export default BackBtn;
