import { Popconfirm } from '@arco-design/web-react'
import React from 'react'

const PopConfirm = ({ children, title, onOk, onCancel }) => {
    return (
        <Popconfirm
            position='left'
            title={title?title: 'Are you sure you want to delete?'}
            onOk={onOk}
            onCancel={onCancel}
            focusLock
        >
            {children}
        </Popconfirm>
    )
}

export default PopConfirm