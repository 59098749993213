
import { ChevronBackOutline } from "react-ionicons"
import Logo from "../../assets/images/Logo.png"
import { useLocation, useNavigate } from "react-router-dom"
import BackBtn from "../../Components/BackBtn/BackBtn"
import { FailedPopup, FormValidation, customLogger, successPopup } from "../../common/Utils/Utils"
import { useCallback, useEffect, useState } from "react"
import moment from "moment"
import { ManageApis } from "../../common/APIS/ManageApis"
import InputField from "../../Components/InputField/InputField"
import VerifyOTP from "../VerifyOTPScreen/VerifyOTP"
import LoginRegLayout from "../UserLoginReg/LoginReg"

function ForgotUsername() {
	//debugger
	const InitialValue = {
		mobile: "",
		dob: ""
	}

	const navigate = useNavigate()
	const { pathname } = useLocation()
	const [form, setform] = useState({
		mobile: "",
		dob: ""
	})
	const [formItem, setformItem] = useState({
		mobile: "",
		dob: ""
	})
	const [verificationData, setverificationData] = useState()
	const [Otp, setOtp] = useState('')
	const [Errors, setErrors] = useState([])
	const [IsLoading, setIsLoading] = useState(false)

	const handleChange = useCallback((value, name) => {
		customLogger(({ value, name }))
		setform(prev => ({ ...prev, [name]: value }))
	}, [])

	const formField = [
		{ type: 'mobile', label: 'Mobile', placeholder: '', required: true, onchange: handleChange, name: 'mobile', disabled: false, value:form.mobile },
		{ type: 'date', label: 'Date of birth', placeholder: '', required: true, onchange: handleChange, name: 'dob', disabled: false, value:form.dob },
	]
	const validate = () => Errors.some(item => item?.message)

	const handleSubmit = async () => {
		let requiredItems = formField.filter(item => item.required).map(item => !!item.required && item.name)
		let validationResult = FormValidation(form, requiredItems)
		if (validationResult.validation) {
			// trigger check username api
			//debugger
			if (!validate()) {
				handleCallApi()
			}

		} else {
			if (validationResult.failedItems.length) {
				let requiredItems = formField.map(item => ({ name: item.name, message: validationResult.failedItems.includes(item.name) ? 'Field is required' : '' }))
				if (!Errors.length) {
					setErrors(requiredItems)
				} else {
					setErrors(prev => [...prev, ...requiredItems])
				}


			}
		}

	};

	const handleCallApi = async () => {
		// call validate fn and is true call backend api
		setIsLoading(true);
		//  axios call

		let body = {
			...form,
			dob: moment(form.dob,'DD-MM-YYYY').format("YYYY-MM-DD"),
		};

		let response = await ManageApis("post", global.forgotUsername, body);
		// if axios success
		if (response.message === "Success") {
			customLogger(response);
			setIsLoading(false);
			successPopup(response.response_data.message)
			const { token_alphabet_part, masked_mobile_number } = response.response_data.data || {}
			setverificationData({ token_alphabet_part, masked_mobile_number })
			setformItem(form)
			setform(form)
			setErrors(["",""])
			navigate('/forgotusername/verifyotp',{state:{form}})
		}
		// axios fail
		else {
			setIsLoading(false);
			customLogger(response.response_data);
			FailedPopup(response);
		}
	}


	const handleErrors = (name, message) => {
		customLogger(name, message)
		if (Errors.length) {
			setErrors(Errors.map(item => item.name === name ? { name: name, message } : item))
		} else
			setErrors(prev => [...prev, { name: name, message }])

	}

	// resend otp
	const handleResendOTP = async () => {
		setOtp('')
		// customLogger("", regData)
		const rqstBody = {
			mobile: formItem.mobile,
			dob: moment(formItem.dob,'DD-MM-YYYY').format("YYYY-MM-DD"),
		};
		let response = await ManageApis("post", global.resentUsernameOtp, rqstBody);
		if (response.message == "Success") {
			customLogger(response, response?.response_data?.data?.token_alphabet_part)
			let token_alphabet_part = response.response_data.data
			if (token_alphabet_part) {
				customLogger(token_alphabet_part)
				setverificationData(prev => ({ ...prev, ...token_alphabet_part }))
			}
			successPopup(response.response_data.message);
		} else {
			customLogger(response)
		}
	};

	// handle varify OTP
	const handleOtpVerification = async (Otp) => {
		const rqstBody = {
			otp: verificationData.token_alphabet_part + Otp,
			mobile: formItem.mobile,
		};
		//debugger
		// axios call for verify mobile
		let response = await ManageApis("post", global.verifyUsernameOtp, rqstBody);
		// if axios call success for verify mobile
		if (response.message == "Success") {
			customLogger("Token", response.response_data.data.token);
			navigate("/changeforgotusername", {
				state: { changeUsernameToken: response.response_data.data.token }
			})
		} else {
			FailedPopup(response);
		}
	};




	if (pathname.includes('/verifyotp')) {
		return (
			<LoginRegLayout loading={IsLoading}>
				<VerifyOTP verificationData={verificationData} handleResendOTP={handleResendOTP} value={Otp} handleSubmit={handleOtpVerification} />
			</LoginRegLayout>

		)

	} else {
		return (<LoginRegLayout loading={IsLoading}>
			<div className="d-flex flex-column h-100 p-5 overflow-auto scrollbar1">
				<div className="">
					<div className="round_sm d-flex bg_gray2 rounded-circle cursor-pointer d-inline-flex px_hover_1">
						<BackBtn />
					</div>
				</div>
				<div className="m-auto max_w_350x py-3 w-100">
					<div>
						<div className="text-center">
							<img src={Logo} className="mb-4 logo_prop" />
						</div>
						<h1 className="h4 text-center text_master fw_700 pb-3">Forgot Username</h1>
						{formField.map(item =>
							<InputField {...item} errorMessage={!!Errors.length && Errors?.find(itm => itm.name == item.name)?.message} seterrorMessage={handleErrors} />
						)}
						<div>
							<button
								// disabled={validate}
								type="submit"
								className="btn px_btn btn-master px-4 rounded w-100 fw_500"
								onClick={handleSubmit}
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
		</LoginRegLayout>

		)
	}

}
export default ForgotUsername

