import '@arco-design/web-react/dist/css/arco.css';

import { useState, useEffect } from 'react';
import { Table, Space, Button } from '@arco-design/web-react';
import { customLogger } from '../../common/Utils/Utils';

function DataTable({ rowData, columnData, total, loading, onChangeTable, current }) {
    // debugger
    const [pagination, setPagination] = useState({
        sizeCanChange: false,
        showTotal: true,
        total: 0,
        pageSize: 10,
        current: 1,
        pageSizeChangeResetCurrent: true,
    });
    // const [loading, setLoading] = useState(loading);

    useEffect(() => {
        setPagination(prev => ({ ...prev, total: total, current: current }))
    }, [total, current])
    customLogger({ rowData })



    return (
        <Table
            loading={loading}
            columns={columnData}
            data={rowData}
            pagination={pagination}
            onChange={onChangeTable}
            borderCell
            // rowSelection={{
            //     selectedRowKeys,
            //     onChange: (selectedRowKeys, selectedRows) => {
            //         customLogger('selectedRowKeys', selectedRowKeys);
            //         customLogger('selectedRows', selectedRows);
            //         setSelectedRowKeys(selectedRowKeys);
            //     },
            // }}
            renderPagination={(paginationNode) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'end',
                        marginTop: 10,
                    }}
                >

                    {paginationNode}
                </div>
            )}
        />
    );
}

export default DataTable;
