import React, { memo, useEffect, useState } from 'react'
import InputField from '../InputField/InputField'
import { preventSpecialCharsandNum } from '../../common/Utils/Utils'

const AutoComplete = ({ data, label, isRequied, placeholder, style, error, onFocus, onClear, value, onChange, errorMessage, seterrorMessage, selectedItemString}) => {
    const [loading, setLoading] = useState(false)
    const [searchInputvalue, setSearchInputvalue] = useState('')
    const [List, setList] = useState([])

    const handleChange = (param) =>
    {
        onChange(param)
        if(param && selectedItemString){
            let stringtext = data.find(item => item.value  == param).key
            selectedItemString(stringtext)
        }
    }



    useEffect(() => {
        setList(data)
    }, [data])

    return (
        <>
            <InputField type={'select'} required={isRequied} marginBottom label={label} options={data} placeholder={placeholder} onclear={onClear} onfocus={onFocus} onchange={handleChange} errorMessage={errorMessage} seterrorMessage={seterrorMessage} value={value}
                onsearchSearchinputchange={(e) => { setSearchInputvalue(preventSpecialCharsandNum(e)) }} searchInputvalue={searchInputvalue} />
        </>
    )
}
export default memo(AutoComplete)