import React from 'react'


const NoData = ({ title, subtitle, icon }) => {
  
    return (
   
        <div className="p-3 bg-white br_10 shadow_sm">
            <div className="d-flex">
                <div className="m-auto">
                    <div className="text-center d-grid py-5 text_gray7">
                        {icon ?
                            {icon}
                            :
                            <i className="far fa-file-alt font_18"></i>
                        }
                        {title ?
                            <p className="mb-0 mt-2">{title}</p>
                            :
                            <p className="mb-0 mt-2">No data found</p>
                        }
                        {subtitle &&
                            <p className="mb-0 mt-1 font_12">{subtitle}</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoData