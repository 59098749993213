
import { RefreshOutline } from "react-ionicons";
import PopupModal from "./PopupModal";


function Captcha() {
  
  return (
    <>
      
      <PopupModal title="Captcha" size="md">
        <div className='py-0 py-md-2'>
          <div className="bg_gray2 p-2 rounded text-center mb-3">
                <span className="d-inline-block font_16 fw_500 py-1">CTy56Vt</span>
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control rounded font_12"
              placeholder="Enter Captcha"
            />
          </div>
          <div className="d-flex gap-3">
            <button
              type="submit"
              className="btn px_btn btn-master-line px-4 rounded fw_500"
            >
              <RefreshOutline width="22px" height="22px"  color={''}  className="text_master" />
            </button>
            <button
              type="submit"
              className="btn px_btn btn-master px-4 rounded w-100 fw_500"
            >
              Login
            </button>
          </div>
        </div>
      </PopupModal>
    </>
  )
}

export default Captcha