import { NewspaperOutline } from "react-ionicons"
import Layout from "../../Components/Layout/Layout"
import GeneralRequestTab from "../RequestTab/GeneralRequestTab"
import { useEffect, useRef, useState } from "react"
import SchemeRequestTab from "../RequestTab/SchemeRequestTab"
import { Nav, Tab } from "react-bootstrap"
import PopupModal from "../../Components/PopupModal"
import AddGeneralRequestTab from "../RequestTab/AddGeneralRequestTab"
import AddSchemeRequestTab from "../RequestTab/AddSchemeRequestTab"
import { customLogger } from "../../common/Utils/Utils"


function MyRequest() {
  const generalRequestTabRef = useRef();
  const schemeRequestTabRef = useRef();
  const [Tabindex, setTabIndex] = useState(0)
  const [index, setIndex] = useState(0)
  const [visible, setVisible] = useState(false)
  const handleModal = () => {
    setVisible(true)
  }



const handleChangeTab = (val) => { setTabIndex(val); setIndex(val) }

const refreshGenTab = () =>{
  //console.log("call General listing")
  if (generalRequestTabRef.current) {
    generalRequestTabRef.current.getGeneralRequestList();
  }
}
const refreshSchemeTab = () =>{
  //console.log("call Scheme listing")
  if (schemeRequestTabRef.current) {
    schemeRequestTabRef.current.getschemeRequestList();
  }
}


  return (
    <Layout>
      <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>
        <div className="mb-3">
          <div className='align-items-center d-flex justify-content-between flex-wrap'>
            <div className='col col-sm d-flex pt-2'>
              <NewspaperOutline height="23px" width="23px" color={''} className="text_master" />
              <h3 className='fw_600 font_18 text_master ms-2 mb-0'>My Request</h3>  
            </div>
            <div className="col-auto col-sm-auto pt-2">
              <button className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100"
                onClick={handleModal}
              >
                New Request
              </button>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <div>
            <Tab.Container defaultActiveKey="General">
              <div className="mb-2 mb-sm-3">
                <Nav className="gap-2 gap-sm-3 nav-tabs nav_tab_t3" activeKey={Tabindex}>
                  <Nav.Item className="flex-fill flex-sm-grow-0">
                    <Nav.Link eventKey="General" onClick={() => handleChangeTab(0)}>General Request</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="flex-fill flex-sm-grow-0">
                    <Nav.Link eventKey="Scheme" onClick={() => handleChangeTab(1)}>Scheme Request</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div>
                <Tab.Content>
                  <Tab.Pane eventKey="General">
                    {
                      Tabindex == 0 ?
                        <GeneralRequestTab index={Tabindex} ref={generalRequestTabRef} /> : ""
                    }
                  </Tab.Pane>
                  <Tab.Pane eventKey="Scheme">
                    {
                      Tabindex == 1 ?
                        <SchemeRequestTab index={Tabindex} ref={schemeRequestTabRef} /> : ""
                    }
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>


      {visible &&
        <PopupModal title={`Add ${index == 0 ? 'General':'Scheme'} Request`} size="md" visible={visible}
          handleClose={() => setVisible(false)}
        >
          <div>
            <Tab.Container defaultActiveKey="General">
              <div className="mb-2 mb-sm-3">
                {/* <Nav className="gap-2 gap-sm-3 nav-tabs nav_tab_t3" activeKey={index}>
                  <Nav.Item className="flex-fill">
                    <Nav.Link eventKey="General" onClick={() => { setIndex(0) }}>General Request</Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="flex-fill">
                    <Nav.Link eventKey="Scheme" onClick={() => { setIndex(1) }}>Scheme Request</Nav.Link>
                  </Nav.Item>
                </Nav> */}
              </div>
              <div>
                <Tab.Content>
                  {index == 0 ?
                    // <Tab.Pane eventKey="General">
                      <AddGeneralRequestTab index={index} setVisible={setVisible} refreshGenTab={refreshGenTab} />
                    // </Tab.Pane>
                    :
                    index == 1 &&
                    // <Tab.Pane eventKey="Scheme">
                      <AddSchemeRequestTab index={index} setVisible={setVisible} refreshSchemeTab={refreshSchemeTab} />
                    // </Tab.Pane>
                  }
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </PopupModal>
      }
    </Layout>

  )

}

export default MyRequest