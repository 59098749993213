import { CalculatorOutline } from "react-ionicons"
import Layout from "../../Components/Layout/Layout"
import InputField from "../../Components/InputField/InputField"
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { ManageApis } from "../../common/APIS/ManageApis"
import { FailedPopup, customLogger, sanitizedText, successPopup } from "../../common/Utils/Utils"
import { ContextAPI } from "../../common/Context/ContextApi"


function EMICalculator({ navigation }) {

  const { token } = useContext(ContextAPI)
  customLogger("test", token)
  const [durationChange, setDurationChange] = useState(0)
  const [InterestRate, setInterestRate] = useState(0)
  const [Amount, setAmount] = useState(0)
  const [EmiAmount, setEmiAmount] = useState(0)
  const [SchemesList, setSchemesList] = useState([])
  const [schemeName, setschemeName] = useState('')
  const [selectedScheme, setselectedScheme] = useState({})
  const [loader, setloader] = useState(false)
  const [Error, setError] = useState('')
  const handleDuration = (value) => {
    // customLogger({value})
    setDurationChange(Number(value))
    setEmiAmount("")
  }

  useEffect(
    () => {
      if (!schemeName) {
        getschemeDetails()
        setEmiAmount('')
      }
    },
    [schemeName]
  )

  // const handleCalculate = () => {
  //   setEmiAmount(calculateEMI(Amount, InterestRate, durationChange))
  // }

  useEffect(() => {
    customLogger("---------------", schemeName);
    if (schemeName) {
      const selectedScheme = SchemesList.find(itm => itm.schemeName === schemeName)
      setselectedScheme(selectedScheme)
      setInterestRate(`${selectedScheme.interestRate}`)
      setDurationChange(Math.round(selectedScheme.maxTenure / 2))
      setAmount(`${Math.round(selectedScheme.maxAmount / 2)}`)
    }
  }, [schemeName])

  const getschemeDetails = () => {
    setloader(true)
    ManageApis('get', global.fetchLoanSchemes, '', token)
      .then((res) => {
        setloader(false)
        const { status, message, data } = res?.response_data || {}
        if (status) {
          data.docs.length && setSchemesList(data.docs)
          customLogger('tt', data.docs[0].schemeName)
          setschemeName(data.docs[0].schemeName)
          // successPopup(message)
        } else FailedPopup(res)
      })
  }
  const getEmiAmount = () => {
    customLogger("amount", Amount);
    if (selectedScheme.minAmount <= Amount && selectedScheme.maxAmount >= Amount) {
      setloader(true)
      const rqstBody = {
        amount: Amount,
        duration: durationChange,
        interest_rate: InterestRate,
        scheme_name: schemeName
      }
      ManageApis('post', global.getEmiDetails, rqstBody, token)
        .then((res) => {
          //debugger
          setloader(false)
          const { status, message, data } = res?.response_data || {}
          if (status) {
            setEmiAmount(data.docs)
            // successPopup(message)
          } else FailedPopup(res)
        })
    } else FailedPopup(`Please enter the amount between ${selectedScheme?.minAmount} - ${selectedScheme?.maxAmount}`)

  }
  const handleChange = (value) => {
    setEmiAmount("")
    setschemeName(value)
  }

  const maxLength = selectedScheme?.maxAmount?.toString().length;

  const handleAmountChange = value => {
    setError('')
    setEmiAmount("")
    value = Number(sanitizedText(value))
    setAmount(value)
    // if (selectedScheme?.minAmount <= value && value <= selectedScheme?.maxAmount) {
    //   setAmount(value)
    //   setError('')
    // }else{
    //   if (selectedScheme?.minAmount <= value) {
    //     setError(`Please enter the amount between ${selectedScheme?.minAmount} - ${selectedScheme?.maxAmount}`)
    //   }
    // }
  }

  return (
    <>
      <Layout>
        <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>
          <div>
            <div className="mb-3">
              <div className='align-items-center d-flex justify-content-between flex-wrap'>
                <div className='col-12 col-sm d-flex pt-2'>
                  <CalculatorOutline height="23px" width="23px" color={''} className="text_master" />
                  <h3 className='fw_600 font_18 text_master ms-2 mb-0'>EMI Calculator</h3>
                </div>
              </div>
            </div>
            <div className="p-3 bg-white br_10 shadow_sm mb-3">
              <div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <InputField label={'Choose Scheme'} type={'select'} onchange={handleChange} options={SchemesList.map(item => ({ key: item.schemeName, value: item.schemeName }))} value={schemeName} />
                  </div>
                  <div className="col-12 col-md-6">
                    <InputField label={'Interest Rate'} disabled type={'text'} value={InterestRate} onchange={(value) => { setInterestRate(sanitizedText(value));  setEmiAmount("")}} />


                  </div>
                  <div className="col-12 col-md-6 mb-3">
                    <div>
                      <InputField marginBottom durationChange label={'Duration'} sliderValue type={'slider'} value={durationChange} minValue={selectedScheme?.minTenure} maxValue={selectedScheme?.maxTenure} handleDuration={handleDuration} />
                      <div className="d-flex justify-content-between">
                        <span>{selectedScheme?.minTenure}</span>
                        <span>{selectedScheme?.maxTenure}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <InputField label={'Amount'} type={'number'} value={Amount} maxLength={maxLength} onchange={handleAmountChange} maxLen={6} helperText={!Error &&`Available Loan Amount ${selectedScheme?.minAmount} - ${selectedScheme?.maxAmount}`} errorMessage={Error} />

                  </div>
                  <div className="col-12 d-flex justify-content-end">
                    <button className="btn btn-master flex-fill flex-md-grow-0 font_12 fw_500 px-3 px-md-5 px_btn rounded" disabled={Error} onClick={getEmiAmount} >Calculate</button>
                  </div>
                </div>
              </div>
            </div>
            {!!EmiAmount &&
              <div className="p-3 bg-white br_10 shadow_sm">
                <div className="text-center">
                  <h5 className="font_16 pt-1 text_master">Instalment to be paid</h5>
                  <h2 className="lh-1 mb-0 text_master2"> {EmiAmount}</h2>
                </div>
              </div>
            }
          </div>
        </div>
      </Layout>
    </>
  )
}
export default EMICalculator