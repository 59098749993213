import React, { useContext, useEffect, useState } from 'react'
import Dashboard from '../Dashboard/Dashboard'
import PopupModal from '../../Components/PopupModal'
import { useNavigate } from 'react-router'
import { async } from 'q'
import { FailedPopup, clearSessionStorage } from '../../common/Utils/Utils'
import Layout from '../../Components/Layout/Layout'
import { ContextAPI } from '../../common/Context/ContextApi'
import { ManageApis } from '../../common/APIS/ManageApis'

const Logout = () => {
    const navigate = useNavigate()
    const [OpenModal, setOpenModal] = useState(true)
    const { settoken, token } = useContext(ContextAPI)
    const [IsLoading, setIsLoading] = useState(false)

    const handleClearAuth = async () => {
        //await clearSessionStorage()
        settoken('')
        return navigate('/login', { replace: true });
    }
    const handleLogout = async () => {
        if (token) {
            setIsLoading(true)
            let response = await ManageApis("get", global.logOut, '', token);
            if (response.message == "Success") {
                handleClearAuth();
                setIsLoading(false)
            } else {
                if (!response.message.includes('Invalid token provided')) {
                    FailedPopup(response);
                }
                handleClearAuth();
                setIsLoading(false)
            }
        } else {
          handleClearAuth()
        }
    };
    const handleCancel= ()=>{
        navigate('/')
    }
    const Buttonprops = [
        { text: 'NO', onPress: handleCancel,variant:'master-line' },
        { text: 'YES', onPress: handleLogout,variant:'master' },
    ]
    return (
        <Layout loading={IsLoading}>
            {OpenModal &&
                <PopupModal visible={OpenModal} handleClose={handleCancel} size={'md'} title={''} message={'Are you sure you want to logout? Please be certain, as logging out will end your current session'} buttons={Buttonprops} />
            }
            <Dashboard />
        </Layout>

    )
}

export default Logout