import { CloudOfflineOutline } from 'react-ionicons'
const NetworkUnavailable = () => {
    return (
        <div className="position-fixed w-100 h-100 top-0 left_0 bg-white d-flex" style={{zIndex:1054}}>
            <div  className="m-auto p-3 text-center">
                <div className="mb-2">
                    <CloudOfflineOutline color={'#000'} width={'40px'} height={'40px'}/>
                </div>
                <h5>You are offline!</h5>
                <div>Make sure you are connected to Wi-Fi or your cellular network.</div>
            </div>
        </div>
    )
}
export default NetworkUnavailable