// import {  useState } from "react"
// import PopupModal from "../../Components/PopupModal"
// import StaticScreen from "../staticScreen/StaticScreen"



function TermsConditions() {
    // const [termsModal, settermsModal] = useState(false)
    // const [privacyModal, setprivacyModal] = useState(false)


	return (
        <>
        <div className="font_10 bg_white35p py-1 px-3">
            
            <div className="align-items-center d-flex justify-content-center justify-content-md-between">
                <div className="pt-1">Version {global.version}</div>
                {/* <div className="d-flex"> 
                    <span className="px_hover_1 cursor-pointer" onClick={() => settermsModal(true)}>Terms and conditions</span>
                    <span className="px-1">|</span>
                    <span className="px_hover_1 cursor-pointer" onClick={() => setprivacyModal(true)}>Privacy Policy</span>
                </div> */}
            </div>
        </div>

        {/* {termsModal &&
            <PopupModal title="Terms and Conditions" size="lg" visible={termsModal} handleClose={() => settermsModal(false)}>
                <StaticScreen title={'Terms'}/>
            </PopupModal>
        }
        {privacyModal &&
            <PopupModal title="Privacy Policy" size="lg" visible={privacyModal} handleClose={() => setprivacyModal(false)}>
                <StaticScreen/>
            </PopupModal>
        } */}
        </>
	)
}
export default TermsConditions

