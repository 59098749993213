
import React, { useState } from 'react'
import BackBtn from '../../Components/BackBtn/BackBtn'
import Layout from '../../Components/Layout/Layout'
import { Nav, Tab } from 'react-bootstrap'
import QuickPayForm from './QuickPayForm'


function QuickPay() {
    const [IsLoading, setIsLoading] = useState(false)
    const [Tabindex, setTabIndex] = useState(0)
    const [index, setIndex] = useState(0)

    const handleChangeTab = (val) => { setTabIndex(val); setIndex(val) }

    return (
        <Layout loading={IsLoading}>
            <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>
                
                
                <div>
                    <div className="mb-3">
                        <div className='align-items-center d-flex justify-content-between flex-wrap'>
                            <BackBtn title={'Quick Pay'} />
                        </div>
                    </div>
                    <div className="p-3 bg-white br_10 shadow_sm">
                        <Tab.Container defaultActiveKey="Chitty">
                        <div className="mb-2 mb-sm-3">
                            <Nav className="gap-2 gap-sm-3 nav-tabs nav_tab_t3" activeKey={Tabindex}>
                            <Nav.Item className="flex-fill flex-sm-grow-0">
                                <Nav.Link eventKey="Chitty" onClick={() => handleChangeTab(0)}>Chitty</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="flex-fill flex-sm-grow-0">
                                <Nav.Link eventKey="Loan" onClick={() => handleChangeTab(1)}>Loan</Nav.Link>
                            </Nav.Item>
                            </Nav>
                        </div>
                        <div>
                            <Tab.Content>
                                <Tab.Pane eventKey="Chitty">
                                    {
                                    Tabindex == 0 ?
                                        <QuickPayForm index={Tabindex} setIsLoading={setIsLoading} /> : ""
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="Loan">
                                    {
                                    Tabindex == 1 ?
                                        
                                        <QuickPayForm index={Tabindex} setIsLoading={setIsLoading} /> : ""
                                        
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                        </Tab.Container>
                    </div>
                    
                </div>
            </div>
        </Layout>
    )
}

export default QuickPay
