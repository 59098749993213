import { CheckmarkCircleOutline, NewspaperOutline } from "react-ionicons"
import Layout from "../../../Components/Layout/Layout"
import BackBtn from "../../../Components/BackBtn/BackBtn"
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ContextAPI } from "../../../common/Context/ContextApi";
import { ManageApis } from "../../../common/APIS/ManageApis";
import { FailedPopup, FormValidation, capitalizeFirstLetter, customLogger, formatDate } from "../../../common/Utils/Utils";
import InputField from "../../../Components/InputField/InputField";
import PopupModal from "../../../Components/PopupModal";

function ChittalDetails() {
	const { state } = useLocation()
	// customLogger({state})
	const navigate = useNavigate()
	// extracting props from urls
	const { chittal_id, chittal_no, ChitDetails, chit_id, branch_id, quickPaydata } = state || {};
	// fetching data from context hook
	const { token, setnotificationsDetails } = useContext(ContextAPI);
	// states
	const [PendingInstallment, setPendingInstallment] = useState([]);
	const [SelectedInstallment, setSelectedInstallment] = useState([]);
	const [SubscriptionAmount, setSubscriptionAmount] = useState("");
	const [ChittalDetails, setChittalDetails] = useState({});
	const [ChitDetailsRemarks, setChitDetailsRemarks] = useState('')
	const [TotalDueAmount, setTotalDueAmount] = useState("");
	const [TotalremitanceAmount, setTotalremitanceAmount] = useState("");
	const [TotalAmountPayable, setTotalAmountPayable] = useState("");
	const [dcSuspense, setdcSuspense] = useState("")
	const [loading, setloading] = useState(false)
	const [suspenseAmt, setsuspenseAmt] = useState("")
	const [Errors, setErrors] = useState({})

	const [InputData, setInputData] = useState('');
	const [error, setError] = useState('');
	const [visible, setvisible] = useState(false)
	const [isEligible, setisEligible] = useState(false)
	const [PBLMessage, setPBLMessage] = useState('')
	const [LoanAmount, setLoanAmount] = useState('')
	const [ModalProps, setModalProps] = useState({
		open: false,
		title: "",
		message: '',
		props: []
	})

	useEffect(() => {
		if (chittal_id && !quickPaydata?.chittal_details) {
			handleGetChittal();
		}
		if (quickPaydata?.chittal_details) {
			let quickPaydataChittal = quickPaydata.chittal_details
			setPendingInstallment(quickPaydataChittal.pending_installments)
			setChittalDetails(quickPaydataChittal)
			setChitDetailsRemarks(quickPaydata?.remarks)
			let array = quickPaydataChittal.pending_installments.map(
				(item) => ({ ...item, isChecked: true })
			);
			//debugger
			//console.log(array)
			setSelectedInstallment(array)
			setSubscriptionAmount(quickPaydataChittal.subscriptionAmount);
			setsuspenseAmt(quickPaydataChittal.suspneseAmt)
			setdcSuspense(quickPaydataChittal.dcSuspense)
			const { suspneseAmt, dcSuspense } = quickPaydataChittal
			handlePaymentData(array, suspneseAmt, dcSuspense);
			setPendingInstallment(array);
		}
		return (() => {
			setModalProps({
				open: false,
				title: "",
				message: '',
				props: []
			})
		})
	}, [])
	
	const handleErrors = (name, message) => {
		customLogger((name, message))
		setErrors({ name: name, message })
	}

	const handleGetChittal = async () => {
		setloading(true)
		const reqstBody = {
			chittal_id: chittal_id,
		};
		let response = await ManageApis(
			"post",
			global.getChittalDetails,
			reqstBody,
			token
		);
		if (response.response_data.status) {
			setloading(false)
			if (response.response_data.data.blocked.status) {
				FailedPopup(response.response_data.data.blocked.message);
				navigate(-1)
			} else {
				setChittalDetails(response.response_data.data);
				setChitDetailsRemarks(response.response_data.data?.remarks)
				customLogger(response.response_data.data)
				let array = response.response_data.data.pending_installments.map(
					(item) => ({ ...item, isChecked: true })
				);
				//debugger
				customLogger(array)
				setSelectedInstallment(array)
				setSubscriptionAmount(response.response_data.data.subscriptionAmount);
				setsuspenseAmt(response.response_data.data.suspneseAmt)
				setdcSuspense(response.response_data.data.dcSuspense)
				const { suspneseAmt, dcSuspense } = response.response_data.data
				handlePaymentData(array, suspneseAmt, dcSuspense);
				setPendingInstallment(array);
				customLogger(array);

			}
			customLogger("resss", response.response_data.data);

		} else {
			setloading(false)
			customLogger("res", response);
			FailedPopup(response);
		}
	};

	const handelcheck = useCallback((data, index) => {

		//customLogger("installment_no",data.installment_no);
		//Toggle checkbox
		let checklist = PendingInstallment.map((itm) =>
			Number(data.installment_no) == Number(itm.installment_no)
				? { ...itm, isChecked: !itm.isChecked }
				: itm
		)
		let currentChecklist = checklist.find(itm => Number(data.installment_no) == Number(itm.installment_no))
		//customLogger("currentChecklist", currentChecklist);
		// check the above items and uncheck the below items
		if (currentChecklist.isChecked == true) {
			//customLogger("if");
			checklist = PendingInstallment.map((itm) =>
				Number(data.installment_no) >= Number(itm.installment_no)
					? { ...itm, isChecked: true }
					: itm
			)
		} else {
			//customLogger("else");
			checklist = PendingInstallment.map((itm) =>

				Number(data.installment_no) <= Number(itm.installment_no)
					? { ...itm, isChecked: false }
					: itm

			)
		}
		
		setPendingInstallment(checklist);
		//customLogger("checklist", checklist);
		let SelectedPendingInstallment = checklist.filter((itm) => itm.isChecked == true)
		setSelectedInstallment(SelectedPendingInstallment);
		customLogger(SelectedInstallment)
		// let totalAmountPayable = 0;
		// SelectedPendingInstallment.reduce(
		//   (itm) => (totalAmountPayable += itm.totalDueAmount)
		// ,0);
		let totalAmountPayable = SelectedPendingInstallment.reduce((total, item) => +item.totalDueAmount, 0);
		//customLogger({totalAmountPayable});
		let sum = (totalAmountPayable) - (suspenseAmt) - (dcSuspense)
		//debugger
		setTotalAmountPayable(totalAmountPayable ? sum : 0);
		setTotalremitanceAmount(totalAmountPayable)
		//debugger
		customLogger(SelectedPendingInstallment.length > 0)
		setInputData(SelectedPendingInstallment.length > 0 && totalAmountPayable ? sum : "0");
		onInputChange(SelectedPendingInstallment.length > 0 && totalAmountPayable ? sum : "");


	}, [PendingInstallment, dcSuspense, suspenseAmt]);

	const handlePaymentData = ((array, suspenseAmt, dcSuspense) => {
		customLogger(array);
		let totalDueAmt = array.reduce(
			(currentValue, itm) => +itm.totalDueAmount,
			0
		);
		customLogger(totalDueAmt);
		setTotalDueAmount(totalDueAmt);
		setTotalremitanceAmount(totalDueAmt)
		
		let sum = (totalDueAmt) - (suspenseAmt) - (dcSuspense)
		setTotalAmountPayable(sum);
		
		setInputData(sum > 0 ? sum : "0");
	});

	const onInputChange = (text) => {
		// customLogger(validateAlphanumericText(text))
		if (/^\d{0,10}$/.test(text)) {
			setInputData(Number(text));
			setErrors(prev => ({ ...prev, message: text > 0 ? '' : 'Field is required' }));
		} else {
			setErrors(prev => ({ ...prev, message: 'Invalid amount' }));
		}
		
	}

	const handleViewPassbook = () => {
		const { branch_id, chit_no } = ChittalDetails
		// return customLogger(ChittalDetails)
		navigate("/ViewPassbookScreen", { chittal_id, branch_id, chit_no, chittal_no });
	};

	const createInstallmenstArray = () => {
		let installmenstArray = SelectedInstallment.map((itm) => (
			{
				"installment_no": itm.installment_no,
				"dividend": itm.dividend,
				"interest": itm.interest,
				"net_amount": itm.net_amount,
				"sync_status": itm.sync_status,
				"startDate": itm.startDate,
				"dueDate": itm.dueDate
			}
		))
		return installmenstArray
	}

	const handleCloseModal = useCallback(() => setModalProps({ open: false, }), [ModalProps])

	const handlePaymentList = async () => {

		let validationResult =""
		if(!InputData){
			validationResult = "Field is required"
		}else if(InputData < 10){
			validationResult = "Minimum amount is 10"
		}else{
			validationResult = ""
		}
		
		// validate
		if (!validationResult) {
			
			if (!validate()) {
				if (!Number(ChittalDetails?.paid_pending_amount) > 0) {
					if (InputData < TotalAmountPayable) {
						// Alert.alert('Are you sure', 'You pay less than the total amount due',)
						setModalProps({
							open: true,
							message: 'The amount you are trying to pay is less than the total payable amount. Do you want to proceed with this amount?',
							props: [
								{ text: 'NO', onPress: handleCloseModal,variant:'master', },
								{ text: 'YES', onPress: triggerAPI ,variant:'master',},
							]
						})
					} else {
						triggerAPI()
					}
				}
				else {
					setModalProps({
						open: true,
						message: `An amount of Rs.${ChittalDetails?.paid_pending_amount} is under processing against chittyno - ${chit_id}. Please try after some time.`,
						// 'Your previous payment is currently being processed. Please try again later.',
						props: [
							{
								text: 'Close',
								onPress: handleCloseModal,
								variant:'master',
							},

						]
					})
				}
			}


			async function triggerAPI() {
				let reqstBody, path;
				// when the user redirected from quick pay screen there are some changes in request body and path
				//debugger
				// if (quickPaydata?.chittal_details?.account_id && quickPaydata) {
				if (quickPaydata) {
					path = global.addUserChittyToCart
					reqstBody = {
						// branch_id: branch_id,
						// chitty_no: chit_id,
						// chitty_class: quickPaydata.chitty_class,
						// chitty_division: quickPaydata.chitty_division,
						// chittal_id: quickPaydata?.chittal_details?.account_id.id, // invalid chittal id error is showing with this ID
						chittal_id: quickPaydata?.chittal_details?._id,
						type: "chitty",
						amount: InputData,
						installments: createInstallmenstArray()
					}

				} else {
					path = global.addToCart
					reqstBody = {
						chittal_id: chittal_id,
						type: "chitty",
						amount: InputData,
						installments: createInstallmenstArray(),
					};
				}
				customLogger("post", path, reqstBody, token)
				if (!reqstBody.installments.length) {
					reqstBody = { ...reqstBody, pay_mode: 'advance' }
				}
				let response = await ManageApis("post", path, reqstBody, token);
				if (response.message == "Success") {
					
					setnotificationsDetails(prev => ({ ...prev, cart_count: prev.cart_count + 1 }))
					customLogger("res", response);
					
					//if (!quickPaydata) {
						setModalProps({
							open: true,
							message: 'Item successfully added to cart, Do you want to add more items?',
							props: [
								{
									text: 'Proceed to cart',
									onPress: () => { navigate("/cart") },
									style: 'cancel',
									variant:'master',
								},
								{ text: 'Yes', onPress: () => navigate("/mychits", { chit_id, branch_id }) ,variant:'master',},
							]
						})
					//} navigate("/cart")


				} else {
					customLogger("res", response);
					if (response.message.includes('You have already added this chittal')) {
						setModalProps({
							open: true,
							title: "Failed!",
							message: response.message,
							props: [
								{ variant: 'master', text: 'Cancel', onPress: handleCloseModal },
								{
									variant:'master',
									text: 'Go to cart',
									onPress: () => { navigate("/cart") },

								},
							]
						});
					} else {
						FailedPopup(response);
					}

				}
			}
		} else {
			let requiredItems = { name: 'InputData', message: validationResult }
			setErrors(requiredItems)
		}
	};

	customLogger({ Errors })


	const getPBLAmount = useCallback(() => {
		setloading(true)
		let rqstBody = {
			"branch_id": branch_id,
			"chittal_no": chittal_no,
			"chitty_no": chit_id
		}
		ManageApis('post', global.getPassbookLoanEligibilityAmount, rqstBody, token)
			.then((res) => {
				// customLogger({ res })
				setloading(false)
				setvisible(true)
				setisEligible(res.response_data.status)
				if (res?.response_data?.message) {
					setPBLMessage(res?.response_data?.message)
				  }else{
					setPBLMessage('')
					FailedPopup(res)
				  }
				// res.response_data.status && 
				setLoanAmount(res?.response_data?.data)

			})
			.catch((err) => {
				setloading(false)
				customLogger({ err })
			})
	}, [])
	const validate = () => Errors.message
	const ChitDetailArray = useMemo(() => [
		
		//{ title: "Chittal Name", value: state?.chittal_name || quickPaydata?.chittal_details?.customer_name},
		{ title: 'Chit Number', value: chit_id },
		{ title: 'Branch Name', value: ChitDetails.branch_name },
		{ title: 'Chit Start Date', value: formatDate(ChitDetails.start_date,  'YYYY-MM-DD', 'DD-MM-YYYY') },
		{ title: 'Chit Termination Date', value: formatDate(ChitDetails.end_date,  'YYYY-MM-DD', 'DD-MM-YYYY') },
		{ title: 'Chit Pattern', value: ChitDetails.chit_pattern },
		{ title: 'Sala', value: ChitDetails.sala },
		{ title: 'Chit Date', value: ChitDetails.auction_day },
        { title: 'Chit Frequency', value: ChitDetails.frequency },
		{ title: "Chittal Name", value: state?.chittal_name || quickPaydata?.chittal_details?.customer_name}
        // { title: 'Current Installment', value: ChitDetails.current_installment },
       // { title: 'Next Auction Date', value: formatDate(ChitDetails?.due_date, 'YYYY-MM-DD', 'DD-MM-YYYY') },
	], [ChitDetails])
	return (
		<Layout loading={loading}>
			{ModalProps?.open &&
				<PopupModal visible={ModalProps.open} handleClose={() => setModalProps({ open: false })} title={ModalProps?.title} message={ModalProps?.message} buttons={ModalProps?.props} />
			}
			{visible &&

			<PopupModal 
				visible={visible} 
				handleClose={() => setvisible(false)} 
				title={'PBL Amount'} 
			>
				<div className="pb-3 text-center">
					<CheckmarkCircleOutline
						color={isEligible ? "green" : "red"} 
						height="50px"
						width="50px"
					/>
				</div>
				<div>
					{PBLMessage &&
						<div className="text-center">
							{(isEligible) ?
								<span>You are eligible for a passbook loan of Rs.<span className="fw_600">{LoanAmount}</span>/-, Please contact Branch</span>
								:
								<span>{PBLMessage}</span>
							}
						</div>
					}
					
				</div>
				
                    
			</PopupModal>
            
          }	
		  	
			<div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>
				<div className="mb-3">
					<div className='align-items-center d-flex justify-content-between flex-wrap'>
						<BackBtn title={`Chittal No ${chittal_no} Details`} />
						{!quickPaydata &&
							<div className="col-12 col-sm-auto pt-3 pt-sm-2">
								<div className="d-flex gap-3">
									{!!ChittalDetails?.prized_status == '0' &&
										<button className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100" onClick={getPBLAmount}>
											<span className="px-0 px-md-3 ws_nowrp">PBL Amount</span>
										</button>
									} 
									<Link to={'/passbook'} state={{ chittal_id, branch_id, chit_id, chittal_no, chittal_name : state?.chittal_name || quickPaydata?.chittal_details?.customer_name  }} className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100 ws_nowrp">
										View Passbook 
									</Link>
								</div>
							</div>
						}
					</div>
				</div>
				<div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3">
					<div className="row">
						{ChitDetailArray.map(item =>
							<div className="col-12 col-md-6 col-lg-4 pb-0 pb-sm-2">
								<div className="row">
									<div className="col-12 col-sm-6 font_13">{item.title}</div>
									<div className="col-12 col-sm-6 pt-1 pt-sm-0 mb-3 mb-sm-0">
										<div className="d-flex">
											<span className="pe-1 d-none d-sm-block">:</span>
											<span className="text-black word_break fw_600 font_13">{item.value}</span>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				{ChitDetailsRemarks &&
					<div className="pb-1 pb-3 pt-2">
						<div className="bg_master2 font_13 px-3 py-3 rounded">
							<span className="fw-bold">Note : </span> 
							{ChitDetailsRemarks||""}
						</div>
					</div>
				}

				<div className="mb-3">
					<div className="mb-3">
						<div className='d-flex pt-2'>
							<NewspaperOutline height="23px" width="23px" color={''} className="text_master" />
							<h3 className='fw_600 font_18 text_master ms-2 mb-0'>My Installment List</h3>
						</div>
					</div>
					<div className="p-3 bg-white br_10 shadow_sm">
						<div className="table_type02 table-responsive custom_scroll1">
							<table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
								<thead className="bg_master text-white">
									<tr>
										<th className="fw_500"></th>
										<th className="fw_500">Installment</th>
										<th className="fw_500" style={{minWidth:100}}>Due Date</th>
										
										<th className="fw_500 text-end">Subscription Amount</th>
										<th className="fw_500 text-end">Due Amount</th>
										<th className="fw_500 text-end">Dividend</th>
										
										<th className="fw_500 text-end">Interest</th>
										<th className="fw_500 text-end">Net Amount</th>
										{/* <th className="fw_500">Start Date</th> */}
										<th className="fw_500 text-end">Total Due Amount</th>
										<th className="fw_500 text-end">Total Amount</th>
									</tr>
								</thead>
								<tbody>
									{PendingInstallment.length ? PendingInstallment.map(item =>
										<tr>
											<td>
												<input type="checkbox" className="form-check-input font_16" onChange={() => handelcheck(item)} checked={item.isChecked} />
											</td>
											<td>{item.installment_no}</td>
											<td>{item.dueDate}</td>
											<td className="text-end">{SubscriptionAmount}</td>
											<td className="text-end">{item.dueAmount}</td>
											<td className="text-end">{item.dividend}</td>
											
											<td className="text-end">{item.interest}</td>
											<td className="text-end">{item.net_amount}</td>
											{/* <td>{item.startDate}</td> */}
											<td className="text-end">{item.totalDueAmount}</td>
											<td className="text-end">{item.totalAmount}</td>
										</tr>
									) :
										<tr>
											<td colSpan={11} className="text-center p-3">No pending installments</td>
										</tr>
									}
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div className="bg-white px-3 pt-3 pb-2 br_10 shadow_sm mb-3">
					<div className="row">
						<div className="col-12 col-md-6"></div>
						<div className="col-12 col-md-6">
							<div className="row mb-0 mb-sm-3">
								<div className="col col-md-6 col-lg-7 font_13 text-md-end mb-2 mb-sm-0">Amount Selected for remittance</div>
								<div className="col-auto col-md-6 col-lg-5 mb-3 mb-sm-0">
									<div className="d-flex justify-content-end">
										<span className="text-black word_break fw_600 font_14">{TotalremitanceAmount}</span>
									</div>
								</div>
							</div>
							<div className="row mb-0 mb-sm-3">
								<div className="col col-md-6 col-lg-7 font_13 text-md-end mb-2 mb-sm-0">Suspense Amount</div>
								<div className="col-auto col-md-6 col-lg-5 mb-3 mb-sm-0">
									<div className="d-flex justify-content-end">
										<span className="text-black word_break fw_600 font_14">{suspenseAmt}</span>
									</div>
								</div>
							</div>
							<div className="row mb-0">
								<div className="col-12 col-md-6 col-lg-7 font_13 text-md-end">Payable Amount</div>
								<div className="col-12 col-md-6 col-lg-5 pt-1 pt-sm-0">
									<div>
										<InputField
											errorMessage={Errors?.message}
											className={'text-end'}
											seterrorMessage={handleErrors}
											type={'number'}
											placeholder={'Enter amount'}
											onchange={onInputChange}
											value={InputData}
											 />
											
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<div className="col-12 col-md-6 col-lg-7"></div>
								<div className="col-12 col-md-6 col-lg-5">
									<div>
										<button className="btn px_btn btn-master px-4 rounded fw_500 font_12 w-100" 
										onClick={(error.message)? ()=>{return} : handlePaymentList}
										disabled={error.message}
										>
											<span className="ws_nowrp">Add to Payment List</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}
export default ChittalDetails