
import React, { forwardRef, useContext,  useEffect,  useImperativeHandle,  useState } from "react";
import { ContextAPI } from "../../common/Context/ContextApi";
import { ManageApis } from "../../common/APIS/ManageApis";
import NoData from "../../Components/NoData/NoData";
import { useNavigate } from "react-router-dom";
import { Spin } from "@arco-design/web-react";
import { customLogger } from "../../common/Utils/Utils";


function SchemeRequestTab(props,ref) {
  const {index} = props
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const { token } = useContext(ContextAPI)
  const [schemeRequest, setschemeRequest] = useState({})


  useImperativeHandle(ref, () => ({
    getschemeRequestList
  }));

    useEffect(() => {
      if (index == 1) {
        customLogger("Scheme Request Tab");
        getschemeRequestList()
      }
     
      
    }, [])


 const getschemeRequestList = async () => {
   setIsLoading(true);
  const reqstBody = {
    request_type: 2
  };
  let response= await ManageApis("post",global.getUserRequest,reqstBody,token)
  if(response?.message =='Success'){
     setIsLoading(false);
    setschemeRequest(response?.response_data?.data?.result)
  }else{
     setIsLoading(false);
    setschemeRequest(response?.response_data?.data?.result)
  }
 }


const handleCardpress = (data) => {
  customLogger('data',data);
  navigate("/chat",{state: data})
}

  // render card when no request found
  const renderEmptyCard = () => {
    return (
      <NoData title={'No request found'}/>
    );
  };

  const renderItem = () => {

    return (
      schemeRequest?.map(itm =>
        <tr>
          <td>{itm.subject}</td>
          {/* <td>{itm.request_to_txt}</td> */}
          <td>
            {itm.request_to_txt}
            {(itm.request_to== 1 && itm.branch_name)? <>({itm.branch_name})</> : ""}
          </td>
          <td>{itm.request_date}</td>
          <td>{itm.scheme_type_txt}</td>
          <td>{itm.subscribed_scheme}</td>
          <td>
            <span className="badge badge_warning_soft font_12 fw_500 rounded-pill">{itm.status_txt}</span>
          </td>
          <td>
            <button className="btn px_btn btn-master-line py-1 rounded fw_500 font_12 w-100 text-nowrap" onClick={() => handleCardpress(itm)} >Chat</button>
          </td>


        </tr>
      )
    );
  };


  return (
  <>
    <Spin dot loading={isLoading}>
    {
      
    !schemeRequest?.length ? (
        renderEmptyCard()
      ) : (
        
        <div className="p-3 bg-white br_10 shadow_sm">
          <div className="table_type02 table-responsive custom_scroll1">
            <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
              <thead className="bg_master text-white">
                <tr>
                  <th className="fw_500">Subject</th>
                  <th className="fw_500">Requested To</th>
                  <th className="fw_500" style={{minWidth:100}}>Requested date</th>
                  <th className="fw_500">Scheme</th>
                  <th className="fw_500">Chit No / Loan Account No</th>
                  <th className="fw_500">Status</th>
                  <th className="fw_500" style={{width:100}}>Action</th>
                </tr>
              </thead>
              <tbody>

                {
                  renderItem()
                }
                
              </tbody>
            </table>
          </div>
        </div>
      )
    }
    </Spin>
    
  </>
  )
  
}

export default  forwardRef(SchemeRequestTab)