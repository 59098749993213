import React, { useContext, useEffect, useState } from 'react'
import { ContextAPI } from '../../common/Context/ContextApi'
import { ManageApis } from '../../common/APIS/ManageApis'
import { FailedPopup, getTimeAgo, successPopup } from '../../common/Utils/Utils'
import NoData from '../../Components/NoData/NoData'

export default function Notification() {
    var data = [{ header: "Paymentt Success", desc: "payment of Rs.2000 with ref.id has been successful." },
    { header: "Customer ID Approved", desc: "Your request for linking Customer id with KSFE is approved." },
    { header: "Paymentt Success", desc: "payment of Rs.2000 with ref.id has been successful." },
    { header: "Paymentt Success", desc: "payment of Rs.2000 with ref.id has been successful." },
    { header: "Customer ID Approved", desc: "Your request for linking Customer id with KSFE is approved." },
    { header: "Paymentt Success", desc: "payment of Rs.2000 with ref.id has been successful." },
    { header: "Paymentt Success", desc: "payment of Rs.2000 with ref.id has been successful." }
    ]
    const { token, setnotificationsDetail, getNotification } = useContext(ContextAPI)

    const [notificationData, setNotificationData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        if (notificationData.length == 0) {
            fetchNotification()
        }
    }, [notificationData.length])

    const fetchNotification = async () => {
        setIsLoading(true)
        let response = await ManageApis("get", global.getNotificationList, "", token)
        //console.log('res', response);
        if (response.message === "Success") {
            setIsLoading(false)
            //console.log("response", response)
            if (response.status_code == 200) {
                setNotificationData(response?.response_data?.data?.notification)
                //console.log(response?.response_data?.data?.notification)

            }

        } else {
            //console.log("response", response)
            FailedPopup(response)
            setIsLoading(false)
        }
    }

    const handleDeleteNotification = async (id) => {
        setIsLoading(true)
        const reqstBody = {
            "notificationId": id
        }
        let response = await ManageApis("post", global.clearNotification, reqstBody, token)
        //console.log('res', response);
        if (response.message === "Success") {
            setIsLoading(false)

            //console.log(notificationData.filter((itm) => itm._id !== id))
            // fetchNotification()
            getNotification()
            // setnotificationsDetail(prev => ({ ...prev, notification_count: prev.notification_count - 1 }))

            let updatedNotification = notificationData.filter((itm) => itm._id !== id)

            setNotificationData(updatedNotification)
            successPopup(response?.response_data.message)

        } else {
            //console.log("response", response)
            FailedPopup(response)
            setIsLoading(false)
        }
    }


    return (
        <>
        <div className="bg-white p-1 rounded shadow">
                <div className="NotifMenuItem scrollbar1 overflow-auto">
                    {notificationData.length ? 
                    <>
                        {notificationData?.map((itm) =>
                        
                            <div className="p-2 border-bottom cursor-pointer">
                                <div className='d-flex gap-2 justify-content-between'>
                                    <h6 className="font_14 fw_600 text_master">{itm.message_header}</h6>
                                    <span  className='px_hover_1 cursor-pointer' onClick={() => handleDeleteNotification(itm._id)}>
                                        <i className="far fa-times-circle"></i>
                                    </span>
                                </div>
                                
                                <div className='d-grid'>
                                    <p className="font_12 mb-1">
                                    {itm.message_body}
                                    </p>
                                    <span className="font_10 text_gray7">{getTimeAgo(itm.created_date)}</span>
                                </div>
                            </div>
                        )}
                    </>
                    
                    :
                        <NoData title="No notification found" />
                    }
                  
                  {/* <div className="p-2 border-bottom px_hover_1 cursor-pointer">
                    <h6 className="font_14 fw_600 text_master">New notification</h6>
                    <p className="font_12 mb-1">This is a test notification.Just ignore it. This is a test notification.Just ignore it.</p>
                    <span className="font_10 text_gray7">2 months ago</span>
                  </div>
                  <div className="p-2 border-bottom px_hover_1 cursor-pointer">
                    <h6 className="font_14 fw_600 text_master">New notification</h6>
                    <p className="font_12 mb-1">This is a test notification.Just ignore it. This is a test notification.Just ignore it.</p>
                    <span className="font_10 text_gray7">2 months ago</span>
                  </div>
                  <div className="p-2 border-bottom px_hover_1 cursor-pointer">
                    <h6 className="font_14 fw_600 text_master">New notification</h6>
                    <p className="font_12 mb-1">This is a test notification.Just ignore it. This is a test notification.Just ignore it.</p>
                    <span className="font_10 text_gray7">2 months ago</span>
                  </div> */}
                </div>
              </div>
        </>
    )
}

