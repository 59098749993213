import { MailOpenOutline } from "react-ionicons"
import InputField from "../../Components/InputField/InputField"
import { useContext, useEffect, useState } from "react"
import { ContextAPI } from "../../common/Context/ContextApi"
import { ManageApis } from "../../common/APIS/ManageApis"
import { FailedPopup, customLogger, successPopup, validateLength } from "../../common/Utils/Utils"
import Layout from "../../Components/Layout/Layout"


function Feedback() {
  const [feedbackType, setFeedbackType] = useState('')
  const [feedbackTypes, setfeedbackTypes] = useState([])
  const [Message, setMessage] = useState('')
  const [Errors, setErrors] = useState({})
  const [Loading, setLoading] = useState(false)
  const { token } = useContext(ContextAPI)
  useEffect(() => {
   
    listFeedBackType();
  }, []);

  useEffect(() => {
   // debugger
   customLogger('unmounting--->')
   // setfeedbackTypes([])
   setFeedbackType('')
   setMessage('')
    
  }, []);

  const handleErrors = (name, message) => {
		customLogger("NAME",name, message)
		setErrors({ name: name, message })
	}

  // get feedback type list
  const listFeedBackType = async () => {
    setLoading(true)
    let response = await ManageApis("get", global.getFeedBackType, '', token);
    if (response.response_data.status) {
      customLogger(response);
      const { feedbackTypes } = response.response_data.data
      if (feedbackTypes.length) {
        let array = feedbackTypes.map(item => ({ key: item.name, value: item.id }))
        customLogger({ array })
        setfeedbackTypes(array)
      }
      setLoading(false)
      
    } else {
      FailedPopup(response);
      setLoading(false)
    }
  };

  
  const handleChange = (value) =>
  {
    setMessage(validateLength(value, 250))
  }
  // handle feedback submit
  const handleFeedBackPress = async () => {
    if (feedbackType && Message) {
      setLoading(true)
      const reqstBody = {
        category_id: feedbackType,
        message: Message,
      }
      // customLogger({ reqstBody })
      // return

      let response = await ManageApis("post", global.sendFeedBack, reqstBody, token)

      customLogger("res", response)
      setLoading(false)
      const { message, status } = response.response_data
      customLogger("m----------------", message)
      if (status) {
        successPopup(message)
        setFeedbackType('')
        setMessage('')
      } else {
        FailedPopup(response)
      }

    } else {
      if (!feedbackType) {
        FailedPopup( 'Fields must not be empty')
        return
      } else if (!Message) {
        FailedPopup( 'Please enter message')
        return
      }

    }
  }
  
  	return (
      	<>
          <Layout>
            <div className='pt-3 pb-4 px-3 px-md-4 overflow-auto custom_scroll1'>	
              <div>
                <div className="mb-3">
                  <div className='align-items-center d-flex justify-content-between flex-wrap'>
                    <div className='col-12 col-sm d-flex pt-2'>
                      <MailOpenOutline height="23px" width="23px"  color={''}  className="text_master" />
                      
                      <h3 className='fw_600 font_18 text_master ms-2 mb-0'>Feedback/Complaint</h3>
                    </div>
                  </div>
                </div>
                <div className="p-3 bg-white br_10 shadow_sm mb-3">
                  <div>
                    <div className="row">
                      <div className="col-12">
                          {/* <label className="mb-0 pb_2x text_master fw_500 font_13">
                          Select an Option<span className="text-danger">*</span>
                          </label>
                          <select className="form-control rounded font_12">
                            <option>Option1</option>
                          </select> */}

                          <InputField 
                            required
                            label={'Select an Option'} 
                            type={'select'}  
                            onchange={(text) => setFeedbackType(text)}
                            options={feedbackTypes} 
                            value={feedbackType}
                          />
                      </div>
                      <div className="col-12">
                          {/* <label className="mb-0 pb_2x text_master fw_500 font_13">
                          Enter your message<span className="text-danger">*</span>
                          </label>
                          <textarea className="form-control rounded font_12" rows={5} placeholder="Enter here..."></textarea> */}
                          <InputField 
                            required
                            errorMessage={Errors?.message}
                            seterrorMessage={handleErrors}
                            label={"Enter your message"}
                            placeholder={'Enter Here'} 
                            type={'textarea'}  
                            helperText={'Maximum 250 characters'}
                            onchange={handleChange}
                            rows={5} 
                            value={Message}
                          />
                      </div>
                      
                      <div className="col-12 d-flex justify-content-end">
                        <button 
                          className="btn btn-master flex-fill flex-md-grow-0 font_12 fw_500 px-3 px-md-5 px_btn rounded" 
                          onClick={handleFeedBackPress}
                        >Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
      	</>
  	)
}
export default Feedback