import React, {  useEffect, useState } from 'react'
import { Spin } from "@arco-design/web-react";
import "./Login.css";
import TermsConditions from "../TermsConditions/TermsConditions";
import { ManageApis } from '../../common/APIS/ManageApis';
import { customLogger } from '../../common/Utils/Utils'
import StaticScreen from '../staticScreen/StaticScreen';
import PopupModal from '../../Components/PopupModal';


function LoginRegLayout({ children, loading }) {


	return (
		<>
		
			<div className="bg-white h_100vh login_screen">
				<div className="d-flex h-100 p-4">
					<div className=" m-auto overflow-hidden w-100 flex-column flex-sm-row d-flex loginbx position-relative zi_1">
						<div className="login_left d-none d-md-flex br_25">
							<div className="p-4 text-white m-auto left_content">
								<div className="pt-4 text_master">
									<h2 className="fw_700 lh_1_5 mb-0 mb-sm-3 pt-5">
										The Kerala State Financial Enterprises Limited
									</h2>
									<div className="">
										<p>
											Upholding The Legacy Since 1969
										</p>
										{/* <p className="font_12 fw_400 lh-base">
											Is one of the most profit-making public sector undertakings of the State and fully owned by the Government of Kerala.
										</p> */}
										{/* {contactDetails &&
											<div className=''>
												<div className='bg-white br_10 d-grid px-3 py-3 text-start shadow_sm'>
													<span className='fw_600 pb-2'>Contact Us</span>
													<p className='font_12 mb-0'>{contactDetails.address || ''}</p>
													{contactDetails.email&&
														<p className='font_12 mb-0'>Email Us: <a href={`mailto: ${contactDetails.email}`} className='text_master2_d1 text-decoration-none fw_500'>{contactDetails.email || ''}</a></p>
													}
													{(contactDetails.mobile || contactDetails.phone)&&
														<p className='font_12 mb-0'>Call Us: <a href={`tel: ${contactDetails.mobile}`} className='text_master2_d1 text-decoration-none fw_500'>{contactDetails.mobile || ''}</a>
															{contactDetails.phone&&
															<a href={`tel: ${contactDetails.phone}`} className='text_master2_d1 text-decoration-none fw_500 ms-2'>{contactDetails.phone || ''}</a>
															}
														</p>
													}
												</div>
											</div>
										} */}
							
										
									</div>
									
								</div>
							</div>
						</div>
						<div className="login_right bg-white h-100 br_25 overflow-hidden position-relative mb-4 mb-md-0">
							<Spin loading={loading} className={'w-100 h-100 arco-spin-child-100'}>
								{children}
								
							</Spin>
						</div>
					</div>
				</div>
				<div className="position-absolute bottom-0 w-100 zi_1">
					<TermsConditions/>
				</div>
			</div>

			
		</>
	)
}

export default LoginRegLayout