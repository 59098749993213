import React, { useCallback, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import InputField from '../../Components/InputField/InputField'
import { FailedPopup, customLogger, getSessionStorage, isValidPanCardNo, successPopup, validateAlphanumeric } from '../../common/Utils/Utils'
import VerifyOTP from '../VerifyOTPScreen/VerifyOTP'
import { Checkbox } from '@arco-design/web-react'
import { ManageApis } from '../../common/APIS/ManageApis'
import { ContextAPI } from '../../common/Context/ContextApi'

const KYCVerification = ({isOTPOpened, setisOTPOpened}) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { token,setkycModal,dashboardListing } = useContext(ContextAPI)
    const [panNo, setpanNo] = useState('')
    const [Errors, setErrors] = useState({})
    const [IsLoading, setIsLoading] = useState(false)
const [isChecked, setisChecked] = useState(false)
const [apiResponse, setapiResponse] = useState({})
    const { mobile } = getSessionStorage('userdetails')

    const handleErrors = (name, message) => {
        setErrors({ name, message })
    }
    const handleChange = (value, name) => {
        //console.log({value})
        setpanNo(value)
    }
    const formField = [
        { type: 'text', label: 'PAN Card Number', placeholder: 'Enter PAN Card Number', required: true, onchange: handleChange, name: 'panNo', disabled: false, },
    ]
    const handleBack = () => {
        setisOTPOpened(false)
    }

// verify pan no
    const handleVerify = async (otp) => {
        // if (panNo >= 4) {
        if (panNo) {
        setIsLoading(true)
          const reqstBody = {
            "PAN": panNo,
            "OTP": `${apiResponse.token_alphabet_part}${otp}`
          };
    
          let response = await ManageApis("post", global.verifyAadhar, reqstBody, token);
          if (response.message == "Success") {
            const currentUrl = window.location.href;
            const endsWithProfile = currentUrl.endsWith("profile");
            if(endsWithProfile) {
                window.location.reload(false)
            }
            setIsLoading(false)
            successPopup(response.response_data.message)
            dashboardListing()
            setisOTPOpened(false)
            setkycModal(false)
          } else {
            setIsLoading(false)
            FailedPopup(response);
          }
        }
      };

    const handleSendOtp = async () => {
        if (panNo) {
            setIsLoading(true)
            const reqstBody = {
                PAN: panNo,
                mobile
            }
            // //console.log(UserData.token, reqstBody)
            let response = await ManageApis('post', global.sendAadharVerificationOtp, reqstBody, token)
            // //console.log('sendAadharVerificationOtp --->', response)
            if (response.response_data.status && response?.response_data?.data) {

                setIsLoading(false)
                // //console.log("res", response)
                const { data: { token_alphabet_part, masked_mobile_number }, message } = response.response_data
                successPopup(message)
                setisOTPOpened(true)
                setapiResponse({token_alphabet_part, masked_mobile_number })
            } else {
                setIsLoading(false)
                FailedPopup(response)
                setisOTPOpened(false)
            }

        } else {
            return setErrors({ name: 'panNo', message: 'Field is required' })
        }
    }
    if (isOTPOpened && apiResponse?.token_alphabet_part ) {
        return (

            <VerifyOTP
            verificationData={apiResponse} 
            handleResendOTP={handleSendOtp} handleSubmit={handleVerify} handleBackBtn={handleBack}
            />

        )

    } else
        return (
            <div className="d-flex flex-column h-100 p-5 overflow-auto scrollbar1">
                <div className="m-auto max_w_350x py-3 w-100">
                    <div>
                        {/* <div className="text-center">
                            <img src={Logo} className="mb-4 logo_prop" />
                        </div> */}
                        <h1 className="h4 text-center text_master fw_700 pb-3">PAN Verification</h1>
                        {formField.map(item =>
                            <InputField {...item}
                                value={panNo}
                                
                                errorMessage={Errors.message} seterrorMessage={handleErrors}
                            />
                        )}
                        <Checkbox checked={isChecked} onChange={setisChecked}>
                           I authorize the KSFE application to use my PAN <span style={{ marginLeft: '22px' }}>card number to complete the e-verification</span> <span style={{ marginLeft: '22px' }}>process.</span>
                        </Checkbox>

                        <div>
                            <button
                                type="submit"
                                className="btn px_btn btn-master px-4 rounded w-100 fw_500 mt-4"
                                onClick={(!isChecked || IsLoading)? ()=>{return} : handleSendOtp}
                                disabled={!isChecked || IsLoading}
                            >
                                Verify PAN
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        )
}

export default KYCVerification