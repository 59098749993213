
import { ChevronBackOutline } from "react-ionicons"
import Logo from "../../assets/images/Logo.png"
import BackBtn from "../../Components/BackBtn/BackBtn"
import { useLocation, useNavigate } from "react-router"
import InputField from "../../Components/InputField/InputField"
import { useCallback, useState } from "react"
import moment from "moment"
import { ManageApis } from "../../common/APIS/ManageApis"
import { FailedPopup, FormValidation, customLogger, successPopup } from "../../common/Utils/Utils"
import LoginRegLayout from "../UserLoginReg/LoginReg"

function ChangeForgotUsername() {
	const navigate = useNavigate()
	const { state } = useLocation()
	const [Errors, setErrors] = useState([])
	const [username, setusername] = useState('')
	const [IsLoading, setIsLoading] = useState(false)

	const handleChange = useCallback((value, name) => {
		customLogger(({ value, name }))
		setusername(prev => ({ ...prev, [name]: value }))
	}, [])

	const formField = [
		{ type: 'username', label: 'Username', placeholder: '', required: true, onchange: handleChange, name: 'username', disabled: false, },
	]
	const handleErrors = (name, message) => {
		if (Errors.length) setErrors(Errors.map(item => item.name === name ? { name: name, message } : item))
		else setErrors(prev => [...prev, { name: name, message }])
	}

	const validate = () => Errors.some(item => item?.message)


	const handleSubmit = async () => {
		let requiredItems = formField.filter(item => item.required).map(item => !!item.required && item.name)
		let validationResult = FormValidation(username, requiredItems)
		if (validationResult.validation) {
			// trigger check username api
			//debugger
			if (!validate()) {
				handleCallApi()
			}

		} else {
			if (validationResult.failedItems.length) {
				let requiredItems = formField.map(item => ({ name: item.name, message: validationResult.failedItems.includes(item.name) ? 'Field is required' : '' }))
				if (!Errors.length) {
					setErrors(requiredItems)
				} else {
					setErrors(prev => [...prev, ...requiredItems])
				}
			}
		}
	};

	const handleCallApi = async () => {
		// call validate fn and is true call backend api
		setIsLoading(true);
		//  axios call

		const rqstBody = {
			...username,
			token: state.changeUsernameToken
		};

		let response = await ManageApis("post", global.changeUsername, rqstBody);
		// if axios success
		if (response.message === "Success") {
			customLogger(response);
			setIsLoading(false);
			successPopup(response.response_data.message)
			navigate('/login')

		}
		// axios fail
		else {
			setIsLoading(false);
			customLogger(response.response_data);
			FailedPopup(response);
		}
	}

	return (
		<LoginRegLayout loading={IsLoading}>
			<div className="d-flex flex-column h-100 p-5 overflow-auto scrollbar1">
				{/* <div className="">
					<BackBtn />
				</div> */}
				<div className="m-auto max_w_350x py-3 w-100">
					<div>
						<div className="text-center">
							<img src={Logo} className="mb-4 logo_prop" />
						</div>
						<h1 className="h4 text-center text_master fw_700 pb-3">Create New Username</h1>
						{formField.map(item =>
							<InputField {...item} errorMessage={!!Errors.length && Errors?.find(itm => itm.name == item.name)?.message} seterrorMessage={handleErrors} />
						)}
						<div className="d-flex gap-3">
							<button
								className="btn px_btn btn-master-line px-4 rounded w-100 fw_500"
								onClick={()=> {navigate('/login')}}
							>
								Cancel
							</button>
							<button
								type="submit"
								className="btn px_btn btn-master px-4 rounded w-100 fw_500"
								onClick={handleSubmit}
							>
								Submit
							</button>
						</div>
					</div>
				</div>
			</div>
		</LoginRegLayout>
	)
}
export default ChangeForgotUsername

