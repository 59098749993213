import React, { useContext, useState } from 'react'
import Dashboard from '../Dashboard/Dashboard';
import Layout from '../../Components/Layout/Layout';





function Home() {
    const [IsLoading, setIsLoading] = useState(false)
	return (
		<Layout loading={IsLoading}>
			<Dashboard />
		</Layout>

	)
}
export default Home