import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { createNewObjectsWithSelectedKeys, customLogger, firstCamelCase, splitCamelCase } from '../../common/Utils/Utils';

function TableComponent({ListingData,linkParam}) {

// let filterData = Object.entries(data)
customLogger("------->",ListingData,linkParam)

if (ListingData.values.length === 0) {
    return <p>No data available.</p>;
}

const columns = ListingData?.header;
let values =createNewObjectsWithSelectedKeys(ListingData?.values,ListingData?.selectedKey)

const excludeKeys = ['']; // Exclude specific keys from being displayed in table columns

  return (
    <div className="p-3 bg-white br_10 shadow_sm">
    <div className="table_type02 table-responsive custom_scroll1">
        <table className="table table-bordered pb-2 mb-0 bg-white rounded-2 border font_13 valign_middle">
            <thead className="bg_master text-white">
            <tr>
            <th className="fw_500">Sl.No</th>
                    {columns?.filter(column => !excludeKeys.includes(column)).map(column => (
                        <th className="fw_500" key={column}>{ firstCamelCase(column)}</th>
                    ))}
                    <th className="fw_500 " style={{width:"100px"}}></th>
                </tr>
            </thead>
            <tbody>

            {values?.map((item, index) => (
                customLogger("index",index,"--->",ListingData?.values[index]),
                    <tr key={item._id}>
                         <td >{index+1}</td>
                        {Object.values(item).map((column,index) => (
                            <td key={index}>{column}</td>
                        ))}
                        <td>
                            <div className="d-flex gap-2 justify-content-end">
                                <Link to={ListingData?.linkto} state={ListingData?.values[index]}className="btn px_btn btn-master-line px-4 rounded fw_500 font_12">
                                    View
                                </Link>
                            </div>
                        </td>
                    </tr>
                ))}
                


            </tbody>
        </table>
    </div>
</div>
  )
}

export default memo(TableComponent)
